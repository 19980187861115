import React from "react";
import { makeDiscountCode, getDiscountCode, } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Statistic, Form, Input, Radio, Space, message,QRCode, } from 'antd';

import { CopyOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Search } = Input;
const { Countdown } = Statistic;


class Discount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disCountCodeType: "disCount",//折扣优惠码
            singleCode: true,//是否是单个码管多次
            leftTimeSeconds: 60 * 60 * 24 * 30,//有效时间
            disCountCodes: [],//返回的优惠码
            searchDiscountCode: null,
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部

    }


    onFinish = (values) => {
        let _this = this;
        // console.log('Success:', values);

        if (values.disCountCodeType == "disCount" && (values.disCountValue > 1 || values.disCountValue <= 0)) {
            message.error("折扣值应在0~1之间！")
            return
        }


        var finalTime = moment().add(values.leftTimeSeconds, 'seconds').valueOf()//.format('YYYY-MM-DD hh:mm:ss'); 
        values["finalTime"] = finalTime

        makeDiscountCode(values).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({
                    disCountCodes: resData
                })
                message.success("创建成功！")
            } else {
                _this.setState({
                    disCountCodes: [],
                })
                message.info("创建失败！")
            }
        });



    };
    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    searchCode = (code) => {
        if (code == "") { return }
        let _this = this;
        getDiscountCode({ "code": code + "" }).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({
                    searchDiscountCode: resData
                })
                // message.success("查询成功！")
            } else {
                _this.setState({
                    searchDiscountCode: null,
                })
                message.info("查询失败！")
            }
        });
    }


    render() {
        const { disCountCodeType, singleCode, leftTimeSeconds, disCountCodes, searchDiscountCode, } = this.state;

        var disCountCodeUrl = window.location.origin + "/price?dc=" + (disCountCodes.length==1 ? disCountCodes[0] : "")
        return (
            <React.Fragment>
                <div className="bodyBox">

                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            disCountCodeType: disCountCodeType,
                            singleCode: singleCode,
                            leftTimeSeconds: leftTimeSeconds,
                        }} //默认值
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="优惠类型"
                            name="disCountCodeType"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入优惠类型!',
                                },
                            ]}
                        >
                            <Radio.Group value={disCountCodeType} onChange={(e) => this.setStateKeyValue("disCountCodeType", e.target.value)}>
                                <Radio.Button value="disCount">折扣</Radio.Button>
                                <Radio.Button value="coupon">代金券</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="优惠码种类"
                            name="singleCode"
                        >
                            <Radio.Group value={singleCode} onChange={(e) => this.setStateKeyValue("singleCode", e.target.value)}>
                                <Radio.Button value={true}>单个码管多次</Radio.Button>
                                <Radio.Button value={false}>多个码管单次</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="有效时间"
                            name="leftTimeSeconds"
                        >
                            <Radio.Group value={leftTimeSeconds} onChange={(e) => this.setStateKeyValue("leftTimeSeconds", e.target.value)}>
                                <Radio.Button value={60}>60秒</Radio.Button>
                                <Radio.Button value={60 * 60 * 24}>1天</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 3}>3天</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 7}>7天</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 30}>1月</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 90}>1季度</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 183}>半年</Radio.Button>
                                <Radio.Button value={60 * 60 * 24 * 365}>一年</Radio.Button>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label={disCountCodeType == "disCount" ? "折扣值" : "代金券金额"}
                            name="disCountValue"
                            rules={[
                                {
                                    required: true,
                                    message: disCountCodeType == "disCount" ? "请输入折扣值!" : "请输入代金券金额!",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>


                        <Form.Item
                            label="优惠码个数"
                            name="disCountNumber"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入优惠码个数!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="代理人ERP ID"
                            name="agentId"
                        >
                            <Input />
                        </Form.Item>




                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                创建
                            </Button>
                        </Form.Item>
                    </Form>

                    {
                        disCountCodes.length > 0 ?
                            <>
                                <br /><br />
                                <Divider plain>创建的优惠码</Divider>
                                <br />
                                <br />
                                <span>{disCountCodeUrl} <CopyOutlined onClick={() => CommonUtils.copyData(disCountCodeUrl)} /></span>
                                <QRCode value={disCountCodeUrl || '-'} errorLevel={"H"} icon={"/favicon.ico"} />
                                <br />
                                <br />
                                <Space wrap>
                                    {
                                        disCountCodes.map(item => {
                                            return (

                                                <span>{item},  </span>

                                            )
                                        })
                                    }
                                    <CopyOutlined onClick={() => CommonUtils.copyData(disCountCodes.join(","))} />
                                </Space>
                                
                            </>
                            : null
                    }


                    <br /><br />
                    <Divider plain>查询优惠码</Divider>
                    <br /><br />

                    <Space wrap>
                        <Search placeholder="输入优惠码" allowClear type="number" onSearch={(value) => this.searchCode(value)} enterButton />
                    </Space>
                    {
                        searchDiscountCode ?
                            <>
                                <br /><br />
                                <Descriptions title={"优惠码: " + searchDiscountCode.code}>
                                    <Descriptions.Item label="优惠类型">{searchDiscountCode.disCountCodeType == "disCount" ? "折扣" : "代金券"}</Descriptions.Item>
                                    <Descriptions.Item label="优惠码种类">{searchDiscountCode.singleCode ? "单个码管多次" : "多个码管单次"}</Descriptions.Item>
                                    <Descriptions.Item label="折扣值/代金券金额">{searchDiscountCode.disCountValue}</Descriptions.Item>
                                    <Descriptions.Item label="优惠码个数">{searchDiscountCode.disCountNumber}</Descriptions.Item>
                                    <Descriptions.Item label="有效时间">{searchDiscountCode.leftTimeSeconds} 秒</Descriptions.Item>
                                    <Descriptions.Item label="剩余有效期" className="priceTime"><Countdown value={searchDiscountCode.finalTime} format="D 天 H 时 m 分 s 秒" /></Descriptions.Item>
                                    {
                                        searchDiscountCode.agentId ?
                                            <Descriptions.Item label="代理人ERP ID">{searchDiscountCode.agentId} </Descriptions.Item>
                                            : null
                                    }
                                </Descriptions>
                            </>
                            : null
                    }




                    <br /><br />
                    <Divider plain>活跃数据</Divider>
                    <br /><br />
                    <Descriptions title="优惠码/团购">
                        <Descriptions.Item label="折扣优惠码">30000</Descriptions.Item>
                        <Descriptions.Item label="代金优惠码">181000</Descriptions.Item>
                        <Descriptions.Item label="团购个数">52318</Descriptions.Item>
                    </Descriptions>

                </div>
            </React.Fragment>
        );
    }
}

export default Discount;