
import React from "react";
import { Row, Col, Image, } from "antd"
import CommonUtils from '../../http/Common.js';

// 展示左边文字右边图片
class TextImageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.content
        };
    }

    render() {
        const { content } = this.state;

        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        return (
            <React.Fragment>
                <div className={content.index % 2 == 0 ? "cardBackground0" : "cardBackground"}>
                    <Row justify={"center"} align={"middle"} >
                        {
                            isPhone ? <>
                                <Col className="cardColBox" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10} style={{ paddingRight: "20px" }}>
                                    <div className="contentTitle">{content.title}</div>
                                    <div className="contentText" >{content.content}</div>
                                </Col>
                                <Col className="cardColBoxImg" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10}>
                                    {/* <Image src={content.img} preview={false} className="imageCardImg"></Image> */}
                                    <Image src={"/img/pro/home1.jpg"} preview={false} className="imageCardImg"></Image>
                                </Col>
                            </>
                                : (
                                    content.index % 2 == 0 ?
                                        <>
                                            <Col className="cardColBox" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10} style={{ paddingRight: "20px" }}>
                                                <div className="contentTitle">{content.title}</div>
                                                <div className="contentText" >{content.content}</div>
                                            </Col>
                                            <Col className="cardColBoxImg" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10} style={{ textAlign: "right" }}>
                                                <Image src={content.img} preview={false} className="imageCardImg"></Image>
                                                {/* <Image src={"/img/pro/home1.jpg"} preview={false} className="imageCardImg"></Image> */}
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col className="cardColBoxImg" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10} style={{ textAlign: "left" }}>
                                                <Image src={content.img} preview={false} className="imageCardImg"></Image>
                                                {/* <Image src={"/img/pro/home1.jpg"} preview={false} className="imageCardImg"></Image> */}
                                            </Col>
                                            <Col className="cardColBox" xs={20} sm={20} md={20} lg={10} xl={10} xxl={10} style={{ paddingLeft: "20px"}}>
                                                <div className="contentTitle">{content.title}</div>
                                                <div className="contentText" >{content.content}</div>
                                            </Col>

                                        </>
                                )

                        }

                    </Row>
                </div>

                {/* {this.props.children} */}
            </React.Fragment>
        );
    }
}

export default TextImageCard;