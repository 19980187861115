import React from "react";
import { getSignByWeb } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, Tabs,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';


class ApiPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      showModel_: false,
      sign: "",
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    //  distinguishBasic({}).then(res=>{

    //     if(res && res.data && res.data.success){
    //            let resData = res.data.data;

    //            message.success("请求成功！")
    //      }else{
    //          _this.setState({
    //             tableData:[],
    //           })
    //        }
    //   }).catch(function (error) {//请求失败时
    //   message.info("创建失败，请重试！店铺名称不能有标点符号")
    // })
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  getSignByWebFun = (params) => {
    let _this = this;
    console.log(params)
    getSignByWeb({ "couponCode": params.sign_base_string }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          sign: resData
        })
        message.info("操作成功！")
      } else {
        _this.setState({
          sign: ""
        })
        message.info("操作失败，请重试！")
      }
    })
  }

  render() {
    const { spinning, sign } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    var columns_table = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '参数名称',
        dataIndex: 'name',
        key: 'name',
        width: 145,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: 65,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '是否必填',
        dataIndex: 'must',
        key: 'must',
        width: 85,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '描述',
        dataIndex: 'detail',
        key: 'detail',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
    ];

    var columns_table_return = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '参数名称',
        dataIndex: 'name',
        key: 'name',
        width: 145,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        width: 65,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '描述',
        dataIndex: 'detail',
        key: 'detail',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
    ];



    var tableData = [
      { "index": 1, "name": "couponCount", "type": "int", "must": "yes", "detail": "生成代金券的数量。" },
      { "index": 2, "name": "couponMoney", "type": "int", "must": "yes", "detail": "一张代金券的金额。couponCount乘以couponMoney的值不能大于剩余的金币数量。否则创建失败。" },
      { "index": 3, "name": "erpUserId", "type": "int", "must": "yes", "detail": "您的erp用户的id。例如：10088。" },
      { "index": 4, "name": "timeStamp", "type": "int", "must": "yes", "detail": "当前时间戳-毫秒。例如：1724295642357。 时间与标准时间戳的差距不能大于3分钟，注意同步服务器时钟。" },
      { "index": 5, "name": "validTime", "type": "int", "must": "yes", "detail": "有效时间。可用值为：1天，3天，1周，1个月，1季度，半年，1年。" },
    ]

    var tableData_return = [
      { "index": 1, "name": "agentUserId", "type": "int", "detail": "您的erp用户id。" },
      { "index": 2, "name": "couponCode", "type": "string", "detail": "生成的代金券验证码。" },
      { "index": 3, "name": "couponType", "type": "string", "detail": "单券多次：表示一个验证码，可以使用多次。创建参数couponCount的值为可领取使用的次数。" },
      { "index": 4, "name": "couponCount", "type": "string", "detail": "可被领取和使用的次数。" },
      { "index": 5, "name": "couponMoney", "type": "string", "detail": "该验证码可抵扣的代金券面值（couponCount大于1时，每张面值都等于该couponMoney值）。" },
      { "index": 6, "name": "endTimeFormat", "type": "string", "detail": "代金券验证码的有效截止时间。" },
      { "index": 7, "name": "endTime", "type": "string", "detail": "代金券验证码的有效截止时间（时间戳/秒）。" },
      { "index": 8, "name": "validTime", "type": "string", "detail": "有效时间。" },
      { "index": 9, "name": "createdTime", "type": "string", "detail": "创建时间。" },
    ]


    const tabs_items = [
      {
        key: '1',
        label: 'Java',
        children: <>
          <textarea className="apiTextarea" disabled>
            {` /**
     *
     * @param sign_base_string： 签名字符串
     * @param secretKey： 加密key
     * @return
     */
    public String getSign(String sign_base_string,String secretKey) {
        String sign = "";

        byte[] partner_key;
        byte[] base_string;
        try {
            base_string = sign_base_string.getBytes("UTF-8");
            partner_key = secretKey.getBytes("UTF-8");
            Mac mac = Mac.getInstance("HmacSHA256");
            SecretKeySpec secret_key = new SecretKeySpec(partner_key, "HmacSHA256");
            mac.init(secret_key);
            sign = String.format("%064x", new BigInteger(1, mac.doFinal(base_string)));
        } catch (Exception e) {
            log.info("签名错误！");
            e.printStackTrace();
        }

        return sign;
    }`}
          </textarea>
        </>,
      },
      {
        key: '2',
        label: 'Python',
        children: <>
          <textarea className="apiTextarea" disabled>
            {` import hmac
import hashlib

#sign_base_string： 签名字符串  ; secretKey： 加密key
def shop_auth(sign_base_string,secretKey):
    partner_key = secretKey.encode()
    base_string = sign_base_string.encode()
    sign = hmac.new(partner_key, base_string, hashlib.sha256).hexdigest()
    return sign
`}
          </textarea>
        </>,
      },
      {
        key: '3',
        label: 'PHP',
        children: <>
          <textarea className="apiTextarea" disabled>
            {` <?php 

function authShop($sign_base_string, $partnerKey) {   
    $sign = hash_hmac('sha256', $sign_base_string, $partnerKey);
    return $sign;
}

$sign_base_string = "couponCount=1&couponMoney=88&erpUserId=10088&timeStamp=1724299512850&validTime=1天";
$partnerKey = "57615053704d6470644f554a78656d50484143644964436a5568777544524579";

echo authShop($sign_base_string, $partnerKey);
?>
`}
          </textarea>
        </>,
      },
      {
        key: '4',
        label: 'GO',
        children: <>
          <textarea className="apiTextarea" disabled>
            {` func auth_shop(){
	secretKey := "1391fd986fe8ec7569bebed75b0c33ee35eb5a305bed7038657a5cd5f75b1c88"
	sign_base_string := "couponCount=1&couponMoney=88&erpUserId=10088&timeStamp=1724299512850&validTime=1天"
	h := hmac.New(sha256.New,[]byte(secretKey))
	h.Write([]byte(baseString))
	sign := hex.EncodeToString(h.Sum(nil))
	fmt.Println(sign)
}
`}
          </textarea>
        </>,
      },
      {
        key: '5',
        label: '在线验证',
        children: <div style={{minHeight:"400px"}}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.getSignByWebFun}
            autoComplete="off"
          >
            <Form.Item label="签名字符串" name="sign_base_string"
              rules={[{
                required: true,
                message: '请输入签名字符串!',
              },]} >
              <Input placeholder="如：couponCount=1&couponMoney=88&erpUserId=10088&timeStamp=1724299512850&validTime=1天" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">
                生成签名
              </Button>
            </Form.Item>
          </Form>
          {
            sign ? <div className="apiBox">
              <span className="apiTitle">签名结果:</span>
              <span>{sign}</span>
            </div> : null
          }

        </div>,
      },
    ];

    var user = CommonUtils.getLocalStorage("user");//缓存用户信息

    return (
      <React.Fragment>

        <div className="apiBox">
          <span className="apiTitle">金币兑换代金券API:</span>
        </div>
        <div className="apiBox">
          <span className="apiTitle">URL:</span>
          <span>https://api.baodanerp.cn/service/erp/coupon/createCoupon</span>
        </div>
        <div className="apiBox">
          <span className="apiTitle">类型:</span>
          <span>POST</span>
        </div>
        <div className="apiBox">
          <span className="apiTitle">JSON参数:</span>
        </div>
        <Table columns={columns_table} dataSource={tableData} size={isPhone ? "small" : "middle"} pagination={{ showSizeChanger: false, }} />

        <div className="apiBox">
          <span className="apiTitle">计算sign:</span>
          <div>签名字符串： couponCount=%s&couponMoney=%s&erpUserId=%s&timeStamp=%s&validTime=%s。将%s替换为对应的值。例如：couponCount=1&couponMoney=88&erpUserId=10088&timeStamp=1724299512850&validTime=1天</div>
          <div>加密key: {user && user.loginTime && new Date().getTime() - user.loginTime < 60000 ? user.secretKey : "请重新登录，可视有效时间1分钟"}。</div>
          <div><span>使用HMAC-SHA256对签名字符串进行哈希，secretKey作为加密key，得出16进制全小写的哈希值即为鉴权签名。</span></div>
        </div>

        <div className="apiBox">
          <span className="apiTitle">计算sign代码:</span>
        </div>
        <Tabs type="card" size={isPhone ? "small" : "middle"} defaultActiveKey="1" items={tabs_items} />

        <div className="apiBox">
          <span className="apiTitle">返回参数:</span>
          <div>success:值为true时表示请求成功。false表示请求失败</div>
          <div>data:返回值的json数据</div>
        </div>
        <Table columns={columns_table_return} dataSource={tableData_return} size={isPhone ? "small" : "middle"} pagination={{ showSizeChanger: false, }} />

      </React.Fragment>
    );
  }
}

export default withRouter(ApiPage);