import React from "react";
import { Descriptions, Divider, Button, Checkbox, QRCode, Radio, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Select, } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import ShareImage from '../ShareImage.js';
import CommonUtils from '../../http/Common';
import ShareBox from './ShareBox';
import WechatJsCommon from './WechatJsCommon';
import ReactWebsocket from './ReactWebsocket.js';
import { getSendConpon, buyTools } from "../../http/api";


class ShareCouponBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            websocket_pay: false,
            make_ShareImage: false,
            showModal_payQrCode: false,
            showModal_addCouponLimit: false,
            sendCouponData: props.sendCouponData,
            hasData: props.sendCouponData ? true : false,//父组件是否带了数据过来
            goldCoins: null,
            payQrCodeUrl: null,
            numberOfAddLimit: 1000,
            couponMoney: 100,
        };
    }

    componentDidMount() {
        let _this = this;
        const { hasData } = this.state;
        // CommonUtils.goToTop()//回到顶部

        //获取缓存的用户信息
        var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
        this.setState({
            "user": user_cache,
        })

        if (!hasData) {
            this.getSendConponFun(100)//查询或者新建代金券
        }
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    //查询或者新建代金券
    getSendConponFun = (couponMoney) => {
        let _this = this;

        if (couponMoney) {
            this.setState({
                couponMoney: couponMoney
            })
        } else {
            couponMoney = this.state.couponMoney
        }

        getSendConpon({ couponMoney: couponMoney }).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({
                    sendCouponData: resData.sendCouponData,
                    goldCoins: resData.goldCoins,
                })
            } else {
                _this.setState({
                    sendCouponData: null,
                    goldCoins: null,
                })
            }
        })
    }


    //充值金币
    buyAddCouponLimit = () => {
        const { numberOfAddLimit } = this.state;
        let _this = this;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()
        var isWeiXin = CommonUtils.isWeiXin()
        var params = {
            "type": "couponLimit",
            "buyCount": numberOfAddLimit,
            "payablePrice": 0.05 * numberOfAddLimit,
            "h5": isPhone,//是否是H5唤起手机支付
            "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
        }
        buyTools(params).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                console.log(resData)

                if (isWeiXin) {
                    _this.setState({
                        websocket_pay: true,
                    })
                    WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)

                } else if (isPhone) {//唤起手机支付
                    _this.setState({
                        websocket_pay: true,
                    })
                    window.open(resData)
                } else {//扫码支付
                    _this.setState({
                        payQrCodeUrl: resData,
                        showModal_payQrCode: true,
                        websocket_pay: true,
                    })
                }

            } else {
                _this.setState({
                    payQrCodeUrl: null,
                    showModal_payQrCode: false,
                    websocket_pay: false,
                })
            }
        })
    }

    //websocket 事件
    onOpen = () => {
        console.log("onOpen")
    }

    //websocket 事件   SUCCESS == 支付成功
    onMessage = (msg) => {
        // console.log("onMessage", msg)
        try {
            msg = JSON.parse(msg)
            if (msg && msg.result && msg.result == "SUCCESS") {
                this.setState({
                    payQrCodeUrl: null,//关闭支付二维码
                    showModal: true,
                    showModal_payQrCode: false,
                    websocket_pay: false,
                    showModal_addCouponLimit: false,
                })

                //缓存权限
                CommonUtils.setLocalStorage("roleData", msg.roleData)
                this.getSendConponFun()//查询或者新建代金券
                message.info("充值成功!")
            }
        } catch (e) { }
    }

    //websocket 事件
    onClose = () => {
        console.log("onClose")
    }

    render() {
        const { sendCouponData, goldCoins, showModal_addCouponLimit, numberOfAddLimit, websocket_pay, payQrCodeUrl, showModal_payQrCode,
            user, hasData,
        } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()
        var buttonSize = isPhone ? "middle" : "large"  //large  middle   small 

        var isWechat = CommonUtils.isWeiXin()


        var couponCode_url = 'https://baodanerp.cn'
        if (user && sendCouponData) {
            var couponCode_url = 'https://baodanerp.cn/home?recommend=' + user.userId + "&couponCode=" + sendCouponData.couponCode
        }

        return (
            <React.Fragment>
                {
                    hasData ? null :
                        <>
                            <div className="shareCouponBoxLimit">现有可兑换金币{goldCoins ? goldCoins.couponMoney : 0}
                                {
                                    sendCouponData ? null :
                                        <Button className="exchangeBtn" style={{ float: "right" }} onClick={() => this.setStateKeyValue("showModal_addCouponLimit", true)}>充值</Button>
                                }
                            </div>
                            <div style={{ margin: "20px 0px" }}>
                                <Popconfirm
                                    title="说明"
                                    description={"当您选择代金券金额具体的金额，系统将为你查询现有的代金券，如果没有对应金额的代金券，将会自动创建，同时扣除相应的金币数量。"}
                                    showCancel={false}
                                    icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                                    style={{ maxWidth: "200px" }}
                                >
                                    <div className="shareCouponBoxTitle displayInlineBlock cursorPointer">代金券金额:</div>
                                </Popconfirm>

                                <Select className="modelSelect shareCouponBoxSelect"
                                    defaultValue={'100元'}
                                    onChange={(value) => this.getSendConponFun(value)}
                                    options={[
                                        { label: '1元', value: 1, },
                                        { label: '5元', value: 5, },
                                        { label: '10元', value: 10, },
                                        { label: '20元', value: 20, },
                                        { label: '50元', value: 50, },
                                        { label: '100元', value: 100, },
                                        { label: '500元', value: 500, },
                                        { label: '1000元', value: 1000, },
                                        // { label: '5000元', value: 5000, },
                                        // { label: '10000元', value: 10000, },
                                    ]}
                                />
                            </div>
                            {
                                sendCouponData ? null : <div className="goldCoinNotEnough">可用金币不足，请先充值</div>
                            }

                        </>
                }


                <div className="shareCouponBox" style={{ margin: "20px 0px" }}>
                    <div className="shareCouponBoxTitle displayInlineBlock">代金券：</div>
                    <div className="displayInlineBlock textAlignCenter otherTextCenter">
                        <div className={sendCouponData ? "" : "no_coupon"}>
                            {
                                sendCouponData ?
                                    <div className="couponCardNew">
                                        <div className="couponCardLeftNew">
                                            代金券
                                        </div>
                                        <div className="couponCardContentNew" style={{ paddingTop: "10px" }}>
                                            <div className="couponCardTitleNew">
                                                <span style={{ fontSize: "16px" }}>￥</span>
                                                {sendCouponData.couponMoney}
                                                <span style={{ fontSize: "16px" }}>元</span>
                                            </div>
                                            <div style={{ color: "#FFF", fontSize: "12px" }}>{sendCouponData.endTimeFormat.substring(0, 10)}过期</div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            <Image src="/img/pro/no_coupon.png" preview={false}></Image>
                                        </div>
                                        <div className="no_coupon_text">暂无代金券</div>
                                    </div>
                            }

                        </div>
                        <div className="displayInlineBlock agentExchangeModelDetail">用户打开链接或者识别二维码可领取代金券</div>
                    </div>
                </div>
                <div className="shareCouponBoxTitle" style={{ margin: "20px 0px 0px 0px" }}>赠送方式：</div>
                <div className="shareCouponBoxShareBox">
                    <ShareBox url={couponCode_url} couponMoney={sendCouponData ? sendCouponData.couponMoney : 100} key={couponCode_url}></ShareBox>
                </div>


                <Modal
                    title={"充值金币"}
                    open={showModal_addCouponLimit}
                    // open={true}
                    onCancel={() => this.setStateKeyValue("showModal_addCouponLimit", false)}
                    onOk={this.buyAddCouponLimit}
                    width={600}
                    cancelText="取消"
                    okText="立即订购"
                    centered
                >
                    <div className="messageBox">
                        <span className="messageTitle">说明:</span>
                        <span className="messageText">充值金币可以用来兑换代金券.</span>
                    </div>
                    <div className="messageBox">
                        <span className="messageTitle">订购套餐:</span>
                        <div>
                            <Radio.Group value={numberOfAddLimit} onChange={(e) => this.setStateKeyValue("numberOfAddLimit", e.target.value)} size={buttonSize}>
                                <Radio.Button value={1000}>一千金币</Radio.Button>
                                <Radio.Button value={3000}>三千金币</Radio.Button>
                                <Radio.Button value={10000}>一万金币</Radio.Button>
                                <Radio.Button value={30000}>三万金币</Radio.Button>
                                <Radio.Button value={50000}>五万金币</Radio.Button>
                                <Radio.Button value={100000}>十万金币</Radio.Button>
                                <Radio.Button value={150000}>十五万金币</Radio.Button>
                                <Radio.Button value={200000}>二十万金币</Radio.Button>
                                <Radio.Button value={500000}>五十万金币</Radio.Button>
                                <Radio.Button value={1000000}>一百万金币</Radio.Button>
                                <Radio.Button value={2000000}>两百万金币</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="messageBox">
                        <span className="messageTitle">应付金额:</span>
                        <span className="textPrice">￥{parseInt(0.05 * numberOfAddLimit)} <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥{parseInt(0.1 * numberOfAddLimit)}</span></span>
                    </div>

                </Modal>

                <Modal
                    title={"扫码支付"}
                    open={showModal_payQrCode}
                    onCancel={() => this.setStateKeyValue("showModal_payQrCode", false)}
                    onOk={() => this.setStateKeyValue("showModal_payQrCode", false)}
                    cancelText="取消"
                    okText="支付完毕"
                    centered
                >
                    {
                        payQrCodeUrl ?
                            <div style={{ textAlign: "center" }}>
                                <div style={{ textAlign: "center", display: "inline-block" }}>
                                    <QRCode value={payQrCodeUrl || '-'} errorLevel={"H"} icon={"/img/wechat_logo.png"} />
                                    微信扫码支付
                                </div>
                                {
                                    isPhone && isWechat ?
                                        <div style={{ margin: "20px 0px" }}>
                                            提示：用手机浏览器发起支付，可自动唤起微信支付。
                                        </div>
                                        : null
                                }
                                <div style={{ margin: "20px 0px" }}>
                                    <Checkbox checked></Checkbox>
                                    <span> 我已阅读并同意</span> <span className="register_xieyi">
                                        <NavLink to="/agreement" target="_blank">
                                            <span  >《爆单ERP系列会员服务协议》</span>
                                        </NavLink>
                                    </span>
                                </div>
                            </div>
                            : <span>获取支付码失败，请重试！</span>
                    }

                </Modal>

                {
                    websocket_pay ?
                        <ReactWebsocket
                            url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/0"}
                            onMessage={this.onMessage} //支付   ---  接受信息的回调
                            onOpen={this.onOpen} //websocket打开 
                            onClose={this.onClose} //websocket关闭
                            reconnect={true}
                            debug={true}
                            ref={Websocket => {
                                this.refWebSocket = Websocket;
                            }}
                        />
                        : null
                }
            </React.Fragment>
        );
    }
}

export default withRouter(ShareCouponBox);