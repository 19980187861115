import React from "react";
import { getCouponList, receiveCoupon, } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Image, Result, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import ShareCard from './ShareImage.js';
import WechatJsCommon from './components/WechatJsCommon';
import { NavLink, withRouter } from 'react-router-dom';


//判断是不是手机屏幕
var isPhone = CommonUtils.isPhoneClient()

class CouponNoUsePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImg: require("../img/advertising.jpg"),
      coupon_noUsed: [],//未使用
      couponCode: "",//验证码
      receiveCouponData: {},
      showModel_getCoupon: false,
      showModel_shareCard: false,
      showModel_receiveSuccess: false,
      // showModel_receiveSuccess: true,
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

    this.getCouponListFun("coupon_noUsed", 1)//查询代金券方法

    //  distinguishBasic({}).then(res=>{

    //     if(res && res.data && res.data.success){
    //            let resData = res.data.data;

    //            message.success("请求成功！")
    //      }else{
    //          _this.setState({
    //             tableData:[],
    //           })
    //        }
    //   }).catch(function (error) {//请求失败时
    //   message.info("创建失败，请重试！店铺名称不能有标点符号")
    // })
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //查询代金券方法
  getCouponListFun = (key, statusType) => {
    let _this = this;
    if (!key || !statusType) {
      return
    }

    getCouponList({ "statusType": statusType }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          [key]: resData
        })

      } else {
        _this.setState({
          [key]: [],
        })
        message.info("请求失败，请重试！")
      }
    });
  }

  sendToFriend = (writeText, couponCode) => {
    this.setState({
      writeText: writeText,
      showModel_shareCard: true,
      qrCodeValue: window.location.origin + "/home?login=true&couponCode=" + couponCode,// 邀请链接
      couponCode: couponCode,
    })
  }

  //领取代金券
  receiveCouponFun = () => {
    const { inputCouponCode } = this.state;
    let _this = this;

    receiveCoupon({ "couponCode": inputCouponCode }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        // console.log("resData===>", resData)

        if (resData.couponMoney) {
          _this.setState({
            showModel_receiveSuccess: true,
            receiveCouponData: resData,
          })
          this.getCouponListFun("coupon_noUsed", 1)//查询代金券方法
        } else {
          Modal.success({
            title: '提示',
            content: resData,
          })
        }

      } else {
        message.info("领取失败，" + res.data.message)
      }
    });
  }



  render() {
    const { coupon_noUsed, showModel_getCoupon, backgroundImg, showModel_shareCard, couponCode, qrCodeValue, writeText, showModel_receiveSuccess,
      receiveCouponData,
    } = this.state;



    return (
      <React.Fragment>
        <div>
          <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModel_getCoupon", true)}>领取代金券</Button>
        </div>
        <div style={{ textAlign: "center" }}>
          {coupon_noUsed.length > 0 ?
            coupon_noUsed.map((t, i) => {
              return (
                <div className="couponCardNew cursorPointer"
                // onClick={() => this.sendToFriend("￥"+t.couponMoney, t.couponCode)}
                >
                  <div className="couponCardLeftNew">
                    代金券
                  </div>
                  <div className="couponCardContentNew">
                    <div className="couponCardTitleNew">
                      <span style={{ fontSize: "16px" }}>￥</span>
                      {t.couponMoney}
                      <span style={{ fontSize: "16px" }}>元</span>
                    </div>
                    <div style={{ color: "#FFF", fontSize: "12px" }}>{t.endTimeFormat.substring(0, 11)}过期</div>
                  </div>
                </div>);
            })
            :
            <div>
              <div>
                <Image src="/img/pro/no_coupon.png" preview={false}></Image>
              </div>
              <div className="no_coupon_text">暂无代金券</div>
            </div>


          }
        </div>



        <Modal title="领取代金券" open={showModel_getCoupon} footer={null} onCancel={() => this.setStateKeyValue("showModel_getCoupon", false)}>
          <div className="getCouponBox">
            <span style={{ lineHeight: "31px" }}>验证码:</span>
            <Input style={{ width: "200px", maxWidth: "38vw", margin: "0px 10px", height: "31px" }} onChange={e => this.setStateKeyValue("inputCouponCode", e.target.value.trim())}></Input>
            <Button type="primary" onClick={this.receiveCouponFun}>领取</Button>
          </div>

        </Modal>

        <Modal title={<>赠送好友<span style={{ fontSize: "12px" }}> - 验证码{couponCode}</span></>} open={showModel_shareCard} footer={null} onCancel={() => this.setStateKeyValue("showModel_shareCard", false)}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <ShareCard
              backgroundImg={backgroundImg}
              qrCodeValue={qrCodeValue}
              writeText={writeText}
              key={writeText}
            />
          </div>
        </Modal>

        <Modal title="" open={showModel_receiveSuccess} footer={null} onCancel={() => { this.setStateKeyValue("showModel_receiveSuccess", false); this.setStateKeyValue("showModel_getCoupon", false) }}>
          <Result
            icon={null}
            // status="success"
            title={"成功获取" + receiveCouponData.couponMoney + "元代金券!"}
            subTitle={
              receiveCouponData ?
                <div>
                  <div className="receiveCouponDetail">{receiveCouponData.couponMoney}元代金券已存入您的账号</div>
                  <div className="couponCardNew cursorPointer">
                    <div className="couponCardLeftNew">代金券</div>
                    <div className="couponCardContentNew">
                      <div className="couponCardTitleNew">
                        <span style={{ fontSize: "16px" }}>￥</span>{receiveCouponData.couponMoney}<span style={{ fontSize: "16px" }}>元</span>
                      </div>
                      {
                        receiveCouponData.endTimeFormat ?
                          <div style={{ color: "#FFF", fontSize: "12px" }}>{receiveCouponData.endTimeFormat.substring(0, 10)}过期</div>
                          : null
                      }

                    </div>
                  </div>
                </div>
                : null
            }
            extra={[
              <NavLink to="/price">
                <Button className="exchangeBtn" style={{ marginRight: "24px" }} onClick={() => this.setStateKeyValue("showModel_getCoupon", false)}>去使用</Button>
              </NavLink>,
              <Button className="exchangeBtn" onClick={() => {
                this.setStateKeyValue("showModel_getCoupon", false);
                this.setStateKeyValue("showModel_receiveSuccess", false);

              }}>去查看</Button>
            ]}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default CouponNoUsePage;