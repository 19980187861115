import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import TextImageCard from './components/TextImageCard';
import MyImageBanner from "./components/MyImageBanner.js";
// import QueueAnim from 'rc-queue-anim';
import { Descriptions, Divider, Button, Checkbox, Form, Input, Radio, Space, message, } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import WechatJsCommon from './components/WechatJsCommon';


class Product extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部
      WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

   }


   render() {
      const { } = this.state;

      // const contentTextObj = [
      //    {
      //       title: "采集商品",
      //       content: ["批量采集商品、单品采集，一键搞定！",
      //          "特别注意，产品重量尽量填写准确（具体品类的重量可问1688客服）。",
      //          "因为没有准确设置重量， 计算出的藏价会比实际的运费少，", "而导致在物流费上面亏很多钱。"],
      //       img: "/img/erp_download_params.jpg",
      //    },
      //    {
      //       title: "商品上传",
      //       content: ["一键上传，自动生成视频，自动添加标签。", "标题自动分词，可添加公共商品描述！", "折扣建议设置30%以上，实现先提价再降价，以后想涨价，直接降低折扣即可，",
      //          "否则如果直接修改价格，会被平台误认为恶意调价，从而降低搜索排名。"],
      //       img: "/img/erp_upload.jpg",
      //    },
      //    {
      //       title: "商品编辑",
      //       content: ["您可以编辑商品的所有属性。可以生成/保留视频！", "修改标题、规格、尺寸、重量， 可以选择尺码图片等。"],
      //       img: "/img/erp_edit.jpg",
      //    },
      //    {
      //       title: "关注大卖店铺的粉丝",
      //       content: ["关注同品类大卖店铺的粉丝，一定程度上可以说是精准引流！","当大卖店铺的粉丝回关您之后，您上新商品时，平台会推送给粉丝。"],
      //       img: "/img/erp_plus_tools.jpg",
      //    },
      //    {
      //       title: "自动确认平台删除",
      //       content: ["商品过多时，平台经常会将浏览量较低的商品下架。","身为卖家，您的时间很宝贵,该功能为您一键搞定！"],
      //       img: "/img/erp_autodelete.jpg",
      //    },
      //    {
      //       title: "收集需求，不断升级",
      //       content: ["我们将不断的升级软件的功能！", "您在不经意间的一个小想法，都可以提交给我们！","我们会尽一切可能，帮您实现！"], 
      //       img: "/img/erp_feedback.jpg",
      //    },
      // ]

      const contentTextObj = [
         {
            title: "采集商品",
            content: '批量商品采集、单品采集，解决无货源问题\n可根据用户重点关注的商品维度进行采集\n可根据商品类别、商品属性、外观条件等作为条件进行采集\n可根据商品销量、商品价格、商品好评排序等条件进行采集\n',
            img: "/img/erp_download_params.jpg",
         },
         {
            title: "商品编辑上架",
            content: '批量编辑商品上架：批量编辑属性、自动翻译、编辑商品标题、批量定价、批量折扣商品、批量加价\n自动生成商品展示视频，描述AI自动翻译成该站点语言\n也可单品编辑商品详情、规格、尺寸、重量， 可以选择尺码图片、文本翻译、AI图片翻译、商品描述等\n一键批量上架，自动生成视频，自动添加标签，效率高\n标题自动分词，可添加公共商品描述',
            img: "/img/erp_upload.jpg",
         },
         {
            title: "线上商品管理",
            content: '多店铺线上商品统一管理、方便批量修改，批量管理\n可统一调整折扣方案、批量下架等',
            img: "/img/erp_edit.jpg",
         },
         {
            title: "店铺订单管理",
            content: "多站点店铺客户订单统一管理，直接去采购\n可统计同一商品的总数量，方便快速确定采购数量",
            img: "/img/erp_plus_tools.jpg",
         },
         {
            title: "后台统一设置",
            content: "便捷对接电商平台店铺、统一添加店铺各种标签：如包邮、正品、优惠券等\n自行选择是否生成商品宣传视频\n设置发货时间、选择是否要自动翻译站点语言等",
            img: "/img/erp_autodelete.jpg",
         },
         {
            title: "收集需求，不断升级",
            content: "我们将不断的升级软件的功能！\n您在不经意间的一个小想法，都可以提交给我们！\n我们会尽一切可能，帮您实现！",
            img: "/img/erp_feedback.jpg",
         },
      ]

      const bannerItemData = {
         "title": "产品",
         "text": "帮助教程详细讲解爆单ERP平台使用方法，包括商品采集、商品批量编辑、商品上架、查询货号、后台设置中心，个人中心，每个步骤都有详细使用步骤，也可以找人工服务",
         "img": "/img/pro/bannerIcon2.png",
         "btn1": { "text": "下载试用", "url": window.location.origin + "/download" },
      }


      var isPhone = CommonUtils.isPhoneClient() //判断是不是手机屏幕
      const bannerData = [
         {
            "img": "/img/pro/banner_product.png",
            "btn1": { "text": "免费试用", "url": window.location.origin + "/download" },
            "btn2": { "text": "了解详情", "url": window.location.origin + "/price", className: "bannerItemBtnColor" }
         },
      ]
      const bannerData_phone = [
         {
            "img": "/img/pro/banner_product_phone.png",
            "btn1": { "text": "免费试用", "url": window.location.origin + "/download" },
            "btn2": { "text": "了解详情", "url": window.location.origin + "/price", className: "bannerItemBtnColor" }
         },
      ]

      return (
         <>
            <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />
            <div className="rootBox">

               {
                  contentTextObj.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index} />)
                  })
               }
            </div>
         </>
      );
   }
}

export default Product;