import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import TextImageCard from './components/TextImageCard';
import FunctionCard from './components/FunctionCard';
// import QueueAnim from 'rc-queue-anim';
import MyImageBanner from "./components/MyImageBanner.js";
import WechatJsCommon from './components/WechatJsCommon';



class Homepage extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dialog: null,
         isPhone: CommonUtils.isPhoneClient(),
      };
   }

   componentDidMount() {
      let _this = this;
      CommonUtils.goToTop()//回到顶部
      WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
      
   }


   render() {
      const { isPhone } = this.state;

      // const contentTextObj = [
      //    {
      //       title: "功能强大，操作简单",
      //       content: ["批量采集商品、上架商品、处理订单，一键搞定！",
      //          "不用频繁安装各种复杂的插件，不用过多的操作,", "系统智能处理。让您成为市场上的明星卖家。"],
      //       img: "/img/showImg1.jpg",
      //    },
      //    {
      //       title: "成功率高",
      //       content: ["采集、上传的成功率高，不断升级迭代软件。", "性价比最高的ERP软件，让您的业务更简单、更高效！"],//跨境电商必备神器
      //       img: "/img/showImg2.jpg",
      //    },
      //    {
      //       title: "丰富资料",
      //       content: ["批量采集图片，规格，描述文字和描述图片,", "并添加正品、优惠、包邮的标签,", "自动合成视频等丰富商品资料。",
      //          "让我们的跨境电商ERP软件助您高效赚取更多的收益！"],
      //       img: "/img/showImg3.jpg",
      //    },
      //    {
      //       title: "自动化工具",
      //       content: ["关注大卖粉丝、一键自动确认平台删除等自动化工具，", "解放您的双手，提高处理效率。"],//身为卖家，您的时间很宝贵
      //       img: "/img/showImg4.jpg",
      //    },
      //    {
      //       title: "性价比最高",
      //       content: ["全网性价比最高的跨境电商ERP软件！", "为您提供最优惠的价格，更强大的功能，让您省时省力省成本！", "更低的运营成本，竞争力也会更加强劲！"], //小本创业也能做大事
      //       img: "/img/showImg5.jpg",
      //    },
      // ]
      const contentTextObj = [
         {
            title: "功能强大，操作简单",
            content: '批量采集商品、上架商品、处理订单，一键搞定！不用频繁安装各种复杂的插件，不用过多的操作,系统智能处理。让您成为市场上的明星卖家。',
            img: "/img/pro/home1.png",
         },
         {
            title: "丰富资料，满足多场景运用",
            content: '批量采集图片，规格，描述文字和描述图片，并添加正品、优惠、包邮的标签,自动合成视频等丰富商品资料。让我们的跨境电商ERP软件助您高效赚取更多的收益！',
            img: "/img/pro/home2.png",
         },

         {
            title: "一键批量处理",
            content: '批量采集商品、上架商品、处理订单，一键搞定！不用频繁安装各种复杂的插件，不用过多的操作,系统智能处理。让您成为市场上的明星卖家。',
            img: "/img/pro/home3.png",
         },

         {
            title: "性价比最高",
            content: '全网性价比最高的跨境电商ERP软件！为您提供最优惠的价格，更强大的功能，让您省时省力省成本！更低的运营成本，竞争力也会更加强劲！',
            img: "/img/pro/home4.png",
         },

      ]



      // var bannerData = [
      //    {
      //       "title": "爆单ERP 超高性价比，小卖家0成本开店，大卖家的成本远低于其他同类ERP",
      //       "text": "爆单ERP的宗旨是帮助跨境电商卖家轻松开店，轻松管理店铺，管理订单，辅助卖家爆单，一起赚钱。小卖家0成本使用，大卖家可以大大降低使用ERP的成本",
      //       "img": "/img/pro/bannerIcon1.png",
      //       "btn1": {"text":"了解详情","url":window.location.origin +"/price"},
      //       "btn2": {"text":"下载试用","url": window.location.origin + "/download",className:"bannerItemBtnColor"},
      //    },
      //    {
      //       "title": "爆单ERP 让我们一起爆单，一起赚钱吧",
      //       "text": "爆单ERP 致力于中小跨境电商卖家轻松便捷开店、管理店铺、管理订单爆单ERP 致力于中小跨境电商卖家轻松便捷开店、爆单ERP 致力于中小跨境电商卖家轻松便捷开店、管理店铺、管理订单爆单ERP 致力于中小跨境电商卖家轻松便捷开店",
      //       "img": "/img/pro/bannerIcon1.png",
      //       "btn1": {"text":"了解详情","url":window.location.origin +"/price"},
      //       "btn2": {"text":"下载试用","url": window.location.origin + "/download",className:"bannerItemBtnColor"},
      //    },
      //    {
      //       "title": "爆单ERP 致力于中小跨境电商卖家轻松便捷开店、管理店铺、管理订单-3",
      //       "text": "爆单ERP 致力于中小跨境电商卖家轻松便捷开店、管理店铺、管理订单爆单ERP 致力于中小跨境电商卖家轻松便捷开店、爆单ERP 致力于中小跨境电商卖家轻松便捷开店、管理店铺、管理订单爆单ERP 致力于中小跨境电商卖家轻松便捷开店",
      //       "img": "/img/pro/bannerIcon1.png",
      //       "btn1": {"text":"了解详情","url":window.location.origin +"/price"},
      //       "btn2": {"text":"下载试用","url": window.location.origin + "/download",className:"bannerItemBtnColor"},
      //    },

      // ]

      const littleCardData = [
         {
            "title": "商品批量采集",
            "text1": "单品采集",
            "text2": "关键词采集",
            "img": "/img/pro/homeIcon1.png",
         },
         {
            "title": "商品批量上架",
            "text1": "批量编辑上架",
            "text2": "Ai图片翻译",
            "img": "/img/pro/homeIcon2.png",
         },
         {
            "title": "商品管理",
            "text1": "多店铺统一管理",
            "text2": "功能强大，操作简单",
            "img": "/img/pro/homeIcon3.png",
         },
         {
            "title": "订单管理",
            "text1": "分类统计订单",
            "text2": "便捷采购",
            "img": "/img/pro/homeIcon4.png",
         },
      ]

      const bannerData = [
         {
            "img":"/img/pro/banner_home1.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home2.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home3.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home4.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/user/agentChange",className:"bannerItemBtnColor"}
         },
      ]

      const bannerData_phone = [
         {
            "img":"/img/pro/banner_home1_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home2_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home3_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/price",className:"bannerItemBtnColor"}
         },
         {
            "img":"/img/pro/banner_home4_phone.png",
            "btn1":{"text":"免费试用","url":  window.location.origin + "/download"},
            "btn2":{"text":"了解详情","url": window.location.origin +"/user/agentChange",className:"bannerItemBtnColor"}
         },
      ]



      return (
         <React.Fragment>

            <MyImageBanner bannerData={isPhone ? bannerData_phone : bannerData} height={isPhone ? "600px" : "400px"} />

            {/* <QueueAnim type={"bottom"} key={"queue"} duration={900} ease="easeInOutQuart" > */}

            <div className="rootBox">
               <FunctionCard data={littleCardData}></FunctionCard>
               {
                  contentTextObj.map((content, index) => {
                     content.index = index
                     return (<TextImageCard content={content} key={index} />)
                  })
               }
            </div>

            {/* </QueueAnim> */}
         </React.Fragment>
      );
   }
}

export default Homepage;