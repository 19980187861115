import React from "react";
import { addCashOut, getCashOutList, getAgentData } from "../http/api";
import CommonUtils from '../http/Common';
import { Spin, Tag, Button, Image, Select, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Upload, Result, Tooltip } from 'antd';
import { QuestionCircleOutlined, CopyOutlined, PlusOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import WechatJsCommon from './components/WechatJsCommon';


//提现记录

class AgentCashOutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      cashOutList: [],
      cashOutList_total: 0,
      cashMoney: 0,
      needCashOut: 0,//待提现
      cashOutCheck: 0,//提现审核中的
      hadCashOut: 0,//已经提现
      canApply: 0,//可以申请的
      showModel_cashout: false,
      showModel_result: false,
      spinning: false,
      previewOpen: false,
      invoiceFile: null,
      previewImage: null,
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部

    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()
      }, 3000);
    } else {
      _this.initFun()
    }

  }

  //初始化方法
  initFun = () => {
    let _this = this;
    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (user_cache) {
      this.setState({
        user: user_cache
      })
    }

    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getcashOutListFun()//查询记录
    this.getAgentDataFun()//查询统计数据
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //添加提现申请
  addCashOutFun = () => {
    let _this = this;
    const { needCashOut, cashOutCheck, hadCashOut, canApply, cashMoney, companyName, companyCode, companyCard, companyBank, invoiceFile } = this.state

    if (!cashMoney) {
      Modal.success({
        title: '提示',
        content: '请先选择提现金额！',
      })
      return
    }

    if (cashMoney > canApply) {
      Modal.success({
        title: '提示',
        content: '提现金额大于可申请提现金额，请选择全部选项！',
      })
      return
    }

    // if (cashMoney <= 2) {
    //   Modal.success({
    //     title: '提示',
    //     content: '金额过小，请再多多增加转化！',
    //   })
    //   return
    // }

    if (cashMoney >= 10000) {
      if (!companyName || !companyCode || !companyCard || !companyBank) {
        Modal.success({
          title: '提示',
          content: '请先完成信息填写，再点击确定！',
        })
        return
      }
    }

    //判断文件不能大于2M
    if (invoiceFile && invoiceFile.size > 1024 * 1024 * 2) {
      var size = invoiceFile.size / (1024 * 1024)
      Modal.success({
        title: '提示',
        content: '您的图片大小为：' + size.toFixed(2) + 'M。发票图片最大不能超过2M！',
      })
      return
    }


    // 构建表单数据
    const formData = new FormData();
    formData.append('cashMoney', cashMoney); //参数
    formData.append('companyName', companyName || ""); //参数
    formData.append('companyCode', companyCode || ""); //参数
    formData.append('companyCard', companyCard || ""); //参数
    formData.append('companyBank', companyBank || ""); //参数
    if (invoiceFile) {
      formData.append('file', invoiceFile.originFileObj)
    }

    addCashOut(formData).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        // message.info("申请成功！请等待审核通过！")
        _this.setState({
          showModel_cashout: false,
          showModel_result: true,
        })


        _this.getcashOutListFun()//查询记录
        _this.getAgentDataFun()//查询统计数据
      } else {
        // message.info("请求失败，请重试！")
        message.info(res.data.message ? res.data.message : "请求失败，请重试！")
      }
    })
  }


  //查询转化记录_分页
  getcashOutListFun_Page = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getcashOutListFun(paramsParams) //查询代理人拆分的代金券
  }

  //查询记录
  getcashOutListFun = (params) => {
    //默认不用时间范围,加上时间范围,查询太慢了(1分钟多).
    let _this = this;
    if (!params) {
      params = {}
    }

    this.setState({
      paramsParams: params,
      spinning: true,
    })


    getCashOutList(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          cashOutList: resData.records,
          cashOutList_total: resData.total,
        })

      } else {
        _this.setState({
          cashOutList: [],
          cashOutList_total: 0,
        })
        message.info("请求失败，请重试！")
      }
    });

  }


  //查询统计数据
  getAgentDataFun = () => {
    let _this = this;
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (!user_cache) { return }//解决退出登录时还会访问的报错

    getAgentData().then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        // console.log("resData===>", resData)
        _this.setState({
          "needCashOut": resData.needCashOut,
          "cashOutCheck": resData.cashOutCheck,
          "hadCashOut": resData.hadCashOut,
          "canApply": resData.needCashOut - resData.cashOutCheck - resData.hadCashOut,
        })
      }
    })
  }

  selectFileChangeFun = ({ fileList: newFileList }) => {
    // console.log("invoiceFile: ",newFileList)
    this.setState({
      invoiceFile: newFileList.length ? newFileList[0] : null
    })

    if (newFileList.length && newFileList[0].size > 1024 * 1024 * 2) {
      var size = newFileList[0].size / (1024 * 1024)
      Modal.success({
        title: '提示',
        content: '您的图片大小为：' + size.toFixed(2) + 'M。发票图片最大不能超过2M！',
      })
    }
  }


  render() {
    const { cashOutList, cashOutList_total, showModel_cashout, cashMoney, spinning, needCashOut, previewImage, previewOpen, invoiceFile,
      cashOutCheck, hadCashOut, canApply, user, showModel_result,
    } = this.state;

    let _this = this;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    const searchItems_CashOut = [
      {
        "title": "申请时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
    ]

    var columns_CashOut = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '账户名称',
        dataIndex: 'userName',
        key: 'userName',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '提现金额',
        dataIndex: 'cashMoney',
        key: 'cashMoney',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '服务费',
        dataIndex: 'serviceMoneyRate',
        key: 'serviceMoneyRate',
        render: (text) => <span>{text * 100}%</span>,
      },
      {
        title: '实际到账',
        dataIndex: 'realityCashMoney',
        key: 'realityCashMoney',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '申请时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '状态',
        dataIndex: 'cashStatus',
        key: 'cashStatus',
        render: (cashStatus) => {
          //状态   1-审核中  2-待转账  3-已提现',
          if (cashStatus == 2) {
            return <Badge count={"待转账"} style={{ backgroundColor: '#FF803A', }} />
          } else if (cashStatus == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#d3d3d3', }} />
          } else if (cashStatus == 3) {
            return <Badge count={"已提现"} style={{ backgroundColor: '#52c41a', }} />
          } else if (cashStatus == -1) {
            return <Badge count={"未批准"} style={{ backgroundColor: '#f00', }} />
          }

        },
      },
      {
        title: '转账时间',
        dataIndex: 'cashTime',
        key: 'cashTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '发票',
        dataIndex: 'invoice',
        key: 'invoice',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text, record, index) => {
          if (text) {
            return <Image width={30} src={"https://kyl-erp.oss-cn-shenzhen.aliyuncs.com/" + text}></Image>
          } else {
            return <span>无</span>
          }
        },

      },
      {
        title: '备注',
        dataIndex: 'remarks',
        key: 'remarks',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },

    ];

    const getBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      // console.log("file.url || file.preview",file.url || file.preview)
      _this.setState({
        previewImage: file.url || file.preview,
        previewOpen: true,
      })
    };


    return (
      <React.Fragment>
        <div className="exchangeCouponHeader" style={isPhone ? { lineHeight: "50px", padding: "15px" } : { lineHeight: "80px" }}>
          <Row justify={"left"} align={"middle"}>
            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4} style={isPhone ? { textAlign: "left" } : { textAlign: "center" }} >
              <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModel_cashout", true)}>提现</Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}  >
              <div>
                <div className="exchangeCouponMy">
                  <Popconfirm
                    title="说明"
                    description={"待提现：" + (needCashOut - hadCashOut) + "元，已经申请并处于审核流程中：" + cashOutCheck + " 元。"}
                    showCancel={false}
                    icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                    style={{ maxWidth: "200px" }}
                  >
                    可申请：{canApply}元
                  </Popconfirm>
                </div>
              </div>


            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}  >
              <div style={{ lineHeight: "20px" }}>
                <div className=" ">服务费：上传发票免服务费
                  <Popconfirm
                    title="说明"
                    description={"发票抬头如下：名称：深圳契富智能科技有限公司，统一社会信用代码：91440300MA5GG6U99F。"}
                    showCancel={false}
                    icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                    style={{ maxWidth: "200px" }}
                  >
                    <QuestionCircleOutlined style={{ color: "#575757" }} />
                  </Popconfirm>

                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4} style={isPhone ? { textAlign: "left" } : { textAlign: "center" }} >
              {/* <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModel_Exchange", true)}>自动提现</Button> */}
            </Col>
          </Row>
        </div>

        <SearchItem searchFun={this.getcashOutListFun} searchItems={searchItems_CashOut} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns_CashOut} dataSource={cashOutList} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
              total: cashOutList_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.getcashOutListFun_Page
            }} />
        </Spin>

        <Modal title="提示" open={showModel_result} footer={null} onCancel={() => this.setStateKeyValue("showModel_result", false)} >
          <Result
            status="success"
            title="申请成功！请等待审核通过！"
            // subTitle="审核时间：1~5个工作日。"
            subTitle={<>
              <div className="displayInlineBlock" style={{ marginLeft: "10px" }}>
                <Image src="/img/pro/qrcode_baodanerp.jpg" preview={false} width={100} height={100} />
                <div style={{ color: "#999dab", textAlign: "center", width: "100px" }}>扫码关注公众号</div>
              </div>
            </>}
            extra={[
              <Button type="primary" key="console" onClick={() => this.setStateKeyValue("showModel_result", false)}>知道了</Button>,
            ]}
          />
        </Modal>

        <Modal title="提现" open={showModel_cashout} footer={null} onCancel={() => this.setStateKeyValue("showModel_cashout", false)} >
          <div className="exchangePagemodelDetail">可申请提现： {canApply}元</div>
          <div>
            <span className="modelTitle">提现金额：
              {/* 1、实名校验：单笔超过2000元时，需传入用户真实姓名并微信已经完成实名校验才可转账成功 */}
            </span>
            <Select className="modelSelect"
              onChange={(value) => this.setStateKeyValue("cashMoney", value)}
              // defaultValue={1000}
              options={user && user.openid && user.openid == "oKNzL6I6qIykkCVUpfGDQQkMTRGQ" ?
                [
                  { label: '全部', value: canApply, },
                  { label: '1元', value: 1, },
                  { label: '1000元', value: 1000, },
                  { label: '2000元', value: 2000, },
                  { label: '5000元', value: 5000, },
                  { label: '10000元', value: 10000, },
                  { label: '20000元', value: 20000, },
                  { label: '100000元', value: 100000, },
                ] :
                [
                  { label: '全部', value: canApply, },
                  { label: '1000元', value: 1000, },
                  { label: '2000元', value: 2000, },
                  { label: '5000元', value: 5000, },
                  { label: '10000元', value: 10000, },
                  { label: '20000元', value: 20000, },
                  { label: '100000元', value: 100000, },
                ]
              }
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <span className="modelTitle">服务费
              <Popconfirm
                title="说明"
                description={"上传发票可免20%的服务费。无发票提供，则需要20%的服务费。"}
                showCancel={false}
                icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                style={{ maxWidth: "200px" }}
              >
                <QuestionCircleOutlined style={{ color: "#575757" }} />
              </Popconfirm>
              ：
            </span>
            {
              invoiceFile ? <span>免服务费</span> : <span>20%； 上传发票免服务费</span>
            }

          </div>
          <div>
            <span className="modelTitle">电子发票：</span>
            <div className="invoiceBox">
              <Upload accept="image/*" listType="picture-circle" maxCount={1}
                onPreview={handlePreview}
                onChange={this.selectFileChangeFun}
                beforeUpload={() => { return false; }}
              >
                <button className="cursorPointer" style={{ border: 0, background: 'none', }} type="button" >
                  <PlusOutlined />
                  {/* <div style={{ marginTop: 8, }} >选择图片</div> */}
                </button>
              </Upload>
              {previewImage &&
                <Image wrapperStyle={{ display: 'none', }} src={previewImage} preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => this.setStateKeyValue("previewOpen", visible),
                  afterOpenChange: (visible) => !visible && this.setStateKeyValue("previewOpen", false),
                }} />
              }
            </div>
          </div>
          {
            cashMoney && cashMoney >= 10000 ?
              <>
                <div>
                  <span className="modelTitle">公司名称：</span>
                  <Input className="modelSelect" placeholder="您的公司名称" onChange={(e) => this.setStateKeyValue("companyName", e.target.value)} />
                </div>
                <div>
                  <span className="modelTitle">信用代码：</span>
                  <Input className="modelSelect" placeholder="您公司的统一社会信用代码" onChange={(e) => this.setStateKeyValue("companyCode", e.target.value)} />
                </div>
                <div>
                  <span className="modelTitle">对公账户：</span>
                  <Input className="modelSelect" placeholder="您公司的对公账户号码" onChange={(e) => this.setStateKeyValue("companyCard", e.target.value)} />
                </div>
                <div>
                  <span className="modelTitle">开户银行：</span>
                  <Input className="modelSelect" placeholder="您公司对公账户的开户银行名称" onChange={(e) => this.setStateKeyValue("companyBank", e.target.value)} />
                </div>
              </> : null
          }
          <div style={{ marginTop: "18px" }}>
            提供发票，可免收服务费20%。发票抬头如下：
          </div>
          <div>
            名称：深圳契富智能科技有限公司 <CopyOutlined className="cursorPointer" onClick={() => CommonUtils.copyData("深圳契富智能科技有限公司")} />
          </div>
          <div>
            统一社会信用代码：91440300MA5GG6U99F <CopyOutlined className="cursorPointer" onClick={() => CommonUtils.copyData("91440300MA5GG6U99F")} />
          </div>
          <div>
            纸质发票请联系平台客服人员。审核时间：1~5个工作日。
          </div>
          {
            cashMoney && cashMoney >= 10000 ?
              <div style={{ color: "#f00" }}>
                提现1万以上，需企业公户提现。
              </div> : null
          }

          <div style={{ marginTop: '28px' }}>
            <Button className="modelBtn modelBtnSure" style={isPhone ? {} : { marginLeft: "80px" }} onClick={this.addCashOutFun}>确定</Button>
            <Button className="modelBtn" onClick={() => this.setStateKeyValue("showModel_cashout", false)}>取消</Button>
          </div>
        </Modal>

      </React.Fragment>
    );
  }
}

export default AgentCashOutPage;