import axios from 'axios';
import CommonUtils from "./Common";
// 创建实例时设置配置的默认值

var baseurl = CommonUtils.baseurl;


/**
 * 自定义实例默认值
 */
var instanceHead = { 'Content-Type': 'application/json;charset=UTF-8' };
var uploadHead = { 'Content-Type': 'multipart/form-data' };

var instance = axios.create({
  baseURL: baseurl, // 公	共接口url（如果有多个的公共接口的话，需要处理）  47.106.128.168    192.168.1.123  localhost:8665
  timeout: 60000, // 请求超时
  headers: instanceHead,  //application/json会请求两次
});

//上传
var upload = axios.create({
  baseURL: baseurl, // 公  共接口url（如果有多个的公共接口的话，需要处理）  47.106.128.168    192.168.1.123  localhost:8665
  timeout: 60000, // 请求超时
  headers: uploadHead,  //application/json会请求两次
});

instance.interceptors.request.use(config => {
  
  var token = CommonUtils.getLocalStorage('token')
  if (token) {
    // 配置请求头 token
    config.headers['token'] = token;
  }
  config.headers['version'] = "web";
  return config;
}
);

upload.interceptors.request.use(config => {
  var token = CommonUtils.getLocalStorage('token')
  if (token) {
    // 配置请求头 token
    config.headers['token'] = token;
  }
  config.headers['version'] = "web";
  return config;
}
);




instance.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // isShowLoading(false);
    //console.log(response);
    // 根据你们家的后端定义请求过期后返回的参数，处理token过期问题
    // 我这个接口木有token啊，这里演示下
    // 判断
    let code = '';
    if (response.data && response.data.errorCode) {
      code = response.data.errorCode;
    }

    // 判断状态码401或者其它条件，不知道判断哪个的去问你家后台
    if (code && code == 401) {
      CommonUtils.goLoginUrl();
      // token过期后处理
      // 1.删除你本地存储的那个过期的token

      // 2. 跳转到登陆页（因为没有装路由，不写了，重新登陆赋值）

      //  todo...
    }
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status == 401) {
      CommonUtils.goLoginUrl();
      return null;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);


upload.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // isShowLoading(false);
    //console.log(response);
    // 根据你们家的后端定义请求过期后返回的参数，处理token过期问题
    // 我这个接口木有token啊，这里演示下
    // 判断
    let code = '';
    if (response.data && response.data.errorCode) {
      code = response.data.errorCode;
    }

    // 判断状态码401或者其它条件，不知道判断哪个的去问你家后台
    if (code && code == 401) {
      CommonUtils.goLoginUrl();
      // token过期后处理
      // 1.删除你本地存储的那个过期的token

      // 2. 跳转到登陆页（因为没有装路由，不写了，重新登陆赋值）

      //  todo...
    }
    return response;
  },
  function (error) {
    if (error && error.response && error.response.status == 401) {
      CommonUtils.goLoginUrl();
      return null;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);



/**
* 使用es6中的类，进行简单封装
*/

// 
class http {
  // 使用async ... await
  static async get(url, params, isShow = false) {
    if (baseurl.includes("localhost")) {
      url = url.replace("\/service", "");
    }
    return await instance.get(url, { params })
  }

  static async post(url, params, isShow = false) {
    if (baseurl.includes("localhost")) {
      url = url.replace("\/service", "");
    }
    return await instance.post(url, params);
  }

  //导出
  static async uploadGet(url, params) {
    if (baseurl.includes("localhost")) {
      url = url.replace("\/service", "");
    }
    return await upload.get(url, { params });
  }

  //上传文件
   // 参数例子： 构建表单数据
  //  const formData = new FormData();
  //  formData.append('file', file); // file是你要上传的文件对象
  //  formData.append('cashMoney', 1); //参数
  static async uploadPost(url, params) {
    if (baseurl.includes("localhost")) {
      url = url.replace("\/service", "");
    }
    return await upload.post(url, params);
  }
}

export default http;