import React from "react";
import { Button, Col, Image, } from "antd"
import CommonUtils from '../../http/Common.js';
import { NavLink } from 'react-router-dom';

//数据格式
// const bannerItemData = {
//     "title": "爆单ERP订购中心",
//     "text": "详细文字",
//     "img": "/img/pro/banner1.jpg",
//     "btn1": {"text":"下载试用","url": window.location.origin + "/download"},
//     "btn2": {"text":"","url":""},
//  }

class BannerItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    imgClickFun = (url) => {
        if (url && url != "") {
            // console.log("url==>",url)
            // window.open(url, "_blank")
            window.open(url, "_self")
            // 刷新当前页面 -- 速度不够快会导致切换失败
            // window.location.reload();
        }
    }

    render() {
        const { data } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        var size_small_or_large = isPhone ? "small" : "large"  //large  middle   small  

        return (
            <div className="bannerItemBox" onClick={() => this.imgClickFun(data.url)}>
                <div className="bannerItemLeft">
                    {
                        data.title ?
                            <div className="bannerItemTitle">{data.title}</div>
                            : null
                    }
                    {
                        data.text ?
                            <div className="bannerItemText">{data.text}</div>
                            : null
                    }

                    <div className="bannerItemBtn">
                        {data.btn1 ?
                            <Button className={data.btn1.className ? "banner_btn " + data.btn1.className  : "banner_btn"} size={size_small_or_large} onClick={() => this.imgClickFun(data.btn1.url)}>{data.btn1.text}</Button>
                            : null
                        }
                        {data.btn2 ?
                            <Button className={data.btn2.className ? "banner_btn " + data.btn2.className  : "banner_btn"} size={size_small_or_large} onClick={() => this.imgClickFun(data.btn2.url)}>{data.btn2.text}</Button>
                            : null
                        }
                    </div>
                </div>

                {
                    data.img ?
                        <div className="bannerItemRight">
                            <div className="bannerItemImg">
                                <Image src={data.img} preview={false}  ></Image>
                            </div>
                        </div>
                        : null
                }



            </div>
        );
    }
}

export default BannerItem;