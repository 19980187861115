import { wechatJsSign } from "../../http/api";
import CommonUtils from '../../http/Common';

// import WechatJsCommon from './components/WechatJsCommon';
// WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

class WechatJsCommon {

	static wechatShareLogo = "https://baodanerp.cn/img/pro/wechatShareLogo.png"

	//给微信的浏览器中签名，以便转发的时候使用到  
	static wechatJsSignFun() {
		// console.log("wechatJsSignFun==>")
		var user = CommonUtils.getLocalStorage("user");//缓存用户信息
		var userId = 10000
		if (user) {
			userId = user.userId
		}
		if (CommonUtils.isWeiXin() && user) {
			wechatJsSign(window.location.href).then(res => {
				if (res && res.data && res.data.success) {
					let resData = res.data.data;
					// console.log(resData)
					window.wx.config({
						debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
						appId: 'wx64f6a8ef0f78e18f', // 必填，公众号的唯一标识
						timestamp: resData.timestamp, // 必填，生成签名的时间戳
						nonceStr: resData.noncestr, // 必填，生成签名的随机串
						signature: resData.sign,// 必填，签名
						jsApiList: ["checkJsApi", "chooseImage", "updateAppMessageShareData", "updateTimelineShareData", "onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的JS接口列表
					});

					//自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
					window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
						window.wx.updateAppMessageShareData({
							title: '加入爆单ERP，一起赚钱一起暴富吧！', // 分享标题
							desc: '注册爆单ERP即可获得，100代金券和1000金币！', // 分享描述
							link: 'https://baodanerp.cn/home?recommend=' + userId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: WechatJsCommon.wechatShareLogo, // 分享图标
							success: function () {
								// 设置成功
							}
						})
					});

					//分享到微信朋友圈   (从微信打开才有效果)
					window.wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
						window.wx.updateTimelineShareData({
							title: '加入爆单ERP，一起赚钱一起暴富吧！', // 分享标题
							link: 'https://baodanerp.cn/home?recommend=' + userId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
							imgUrl: WechatJsCommon.wechatShareLogo, // 分享图标
							success: function () {
								// 设置成功
							}
						})
					});
				}
			})
		}
	}

	//送代金券时设置转发内容
	static sendCouponToFriend(link_url, couponMoney) {
		if (CommonUtils.isWeiXin()) {
			var user = CommonUtils.getLocalStorage("user");//缓存用户信息
			var userId = 10000
			if (user) {
				userId = user.userId
			}

			if (!couponMoney) {
				couponMoney = 1
			}

			// var link_url = 'https://baodanerp.cn/home?recommend=' + userId
			// if (couponCode) {
			// 	link_url = 'https://baodanerp.cn/home?recommend=' + userId + "&couponCode=" + couponCode
			// }

			//自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
			window.wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
				window.wx.updateAppMessageShareData({
					title: '赠送您' + couponMoney + '元爆单ERP代金券', // 分享标题
					desc: '快来看看，不要错过优惠哦！', // 分享描述
					link: link_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: WechatJsCommon.wechatShareLogo, // 分享图标
					success: function () {
						// 设置成功
					}
				})
			});

			//分享到微信朋友圈   (从微信打开才有效果)
			window.wx.ready(function () {      //需在用户可能点击分享按钮前就先调用
				window.wx.updateTimelineShareData({
					title: '赠送您' + couponMoney + '元爆单ERP代金券，快来看看，不要错过优惠哦！', // 分享标题
					link: link_url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
					imgUrl: WechatJsCommon.wechatShareLogo, // 分享图标
					success: function () {
						// 设置成功
					}
				})
			});
		}
	}

	//调起微信支付
	static jsApiPay(appId,timeStamp,nonceStr,packageStr,paySign) {
		if (CommonUtils.isWeiXin()) {
			function onBridgeReady() {
				window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
					"appId": appId,     //公众号ID，由商户传入     
					"timeStamp": timeStamp,     //时间戳，自1970年以来的秒数     
					"nonceStr": nonceStr,      //随机串     
					"package": packageStr,
					"signType": "RSA",     //微信签名方式：     
					"paySign": paySign //微信签名 
				},
				function(res) {
					if (res.err_msg == "get_brand_wcpay_request:ok") {
						// 使用以上方式判断前端返回,微信团队郑重提示：
						//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
						alert("OK")
					}
				});
			}

			if (typeof window.WeixinJSBridge == "undefined") {
				if (document.addEventListener) {
					document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
				} else if (document.attachEvent) {
					document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
					document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
				}
			} else {
				onBridgeReady();
			}

			onBridgeReady();
		}
	}

}
export default WechatJsCommon;