import React from "react";
import { wechatJsSign } from "../http/api";
import CommonUtils from '../http/Common';
import WechatJsCommon from './components/WechatJsCommon';
import ShareBox from './components/ShareBox';
import ShareCouponBox from './components/ShareCouponBox';
import { Popover, Divider, Button, QRCode, Image, Input, Col, Row, Modal, message, } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import ShareImage from './ShareImage.js';

//邀请好友

class AgentRecommendPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      showModel_sendCoupon: false,
      showModel_recommend: false,
      make_ShareImage: false,
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部

    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()
      }, 3000);
    } else {
      _this.initFun()
    }


  }

  //初始化方法
  initFun = () => {
    let _this = this;
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 

    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    this.setState({
      "user": user_cache,
    })
  }


  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //分享到微信  (从微信打开才有效果)
  sendFun_wechat = () => {
    let _this = this;
    console.log("分享到微信==>")
    if (CommonUtils.isWeiXin()) {
      Modal.success({
        title: '分享内容已准备好',
        content: '点击右上角的...可分享给朋友！',
      })
    } else {
      this.setState({
        make_ShareImage: true
      })

      setTimeout(() => {
        this.setState({
          make_ShareImage: false
        })
        Modal.success({
          title: '图片已下载',
          content: '将图片转发给朋友,扫码进入即可邀请成功！',
        })
      }, 2000);


    }

  }


  render() {
    const { showModel_sendCoupon, showModel_recommend, showModel_sendCoupon_key, user, } = this.state;

    var recommendUrl = 'https://baodanerp.cn'
    if (user) {
      recommendUrl = 'https://baodanerp.cn/home?recommend=' + user.userId
    }


    return (
      <React.Fragment>
        <div className="agentInviteHeader">
          <div className="agentInviteHeaderLogo">
            <img className="agentInviteHeaderLogoImg" src="/img/pro/logoWhite.png" preview={false}></img>
            爆单ERP
          </div>
          <div className="agentInviteHeaderText">
            邀请越多人加入爆单ERP，赚取越多收益！
          </div>
          <img className="agentPagePeople" src="/img/pro/agentPeople.png" preview={false}></img>
        </div>
        <div className="agentInviteTextBox">
          <div style={{ color: "#666666", fontSize: "14px", marginBottom: "61px" }}>邀请好友（客户）加入爆单ERP平台可获取以下三种权益</div>

          <Row gutter={[16, 24]} justify={"left"} align={"middle"}>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img className="agentIcon" src="/img/pro/agentIcon1.png" ></img>
                <div className="inviteTitle">按比例提成客户消费额度</div>
                <div className="inviteText">首月按50%提成客户消费额度</div>
                <div className="inviteText">长期按30%提成客户消费额度</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img className="agentIcon" src="/img/pro/agentIcon2.png" ></img>
                <div className="inviteTitle">获赠100金币、100代金券</div>
                <div className="inviteText">邀请新用户完成注册，代理人获得</div>
                <div className="inviteText">100金币、100元代金券</div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img className="agentIcon" src="/img/pro/agentIcon3.png" ></img>
                <div className="inviteTitle">按比例提成客户消费代金券额度</div>
                <div className="inviteText">客户通过您赠送的代金券消费您可</div>
                <div className="inviteText">获得相应比例的现金提成</div>
              </div>
            </Col>
          </Row>
          <div className="agentInviteBtnBox">
            <Button style={{ background: "#0766F4", color: "#FFF", borderRadius: "2px", marginRight: "20px" }}
              onClick={() => this.setStateKeyValue("showModel_recommend", true)}>立即邀请</Button>
            <Button style={{ background: "#F92D2D", color: "#FFF", borderRadius: "2px" }}
              onClick={() => {
                this.setStateKeyValue("showModel_sendCoupon", true);
                this.setStateKeyValue("showModel_sendCoupon_key", (new Date()).getTime())
              }}>赠送代金券</Button>
          </div>

        </div>



        <Modal title="赠送代金券" open={showModel_sendCoupon} footer={null} onCancel={() => this.setStateKeyValue("showModel_sendCoupon", false)} >
          <ShareCouponBox key={showModel_sendCoupon_key}></ShareCouponBox>
        </Modal>

        <Modal title="邀请好友" open={showModel_recommend} footer={null} onCancel={() => this.setStateKeyValue("showModel_recommend", false)}>
          <div className="recommendBox">
            <ShareBox url={recommendUrl}></ShareBox>
          </div>
        </Modal>

      </React.Fragment >
    );
  }
}

export default AgentRecommendPage;