import React from "react";
import { Button, QRCode } from "antd"
import { wechatJsSign } from "../http/api";

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        let _this = this;


        // wechatJsSign({ "url": window.location.href.split("#")[0] }).then(res => {

        //     if (res && res.data && res.data.success) {
        //         let resData = res.data.data;
        //         console.log(resData)
        //     } else {
        //         _this.setState({
        //             tableData: [],
        //         })
        //     }
        // })
    }




    render() {
        const { } = this.state;

        return (
            <React.Fragment>

                <Button className="selectRightIcon">测试</Button>

            </React.Fragment>
        );
    }
}

export default Test;





