import React from "react";
import { getSumOfCoupon, getAgentData, getLimitMoney } from "../http/api";
import CommonUtils from '../http/Common';
import { Layout, Menu, Col, Row, Image, Button, Card, message, Tooltip, Badge } from 'antd';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
const { Header, Content, Sider } = Layout;





class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponTotal_noUse: 0,
      couponTotal_used: 0,
      baseCount: 0,
      recommendCount: 0,
      shopCount: 0,
      earnMoney: 0,//总收益
      needCashOut: 0,//待提现
      checkMoney: 0,//审核中
      hadCashOut: 0,//已提现
      lastUpdateTime: "",//已提现
      couponMoney_limit: 0,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    // this.getSumOfCouponFun(1); //查询代金券总和
    // this.getSumOfCouponFun(2); //查询代金券总和


    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录

    if (window.location.href.includes("/user/") && !isWeiXinLogin) { //没登陆的时候打开用户界面
      //如果用户未登录，返回到登录界面
      var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
      if (!user_cache) {
        // window.location.href = window.location.origin + "/home?login=true";  // 必须重定向到/home， 在用户界面会报错
        var url = window.location.origin + "/home?login=true";  // 必须重定向到/home， 在用户界面会报错
        window.open(url, "_self")
        // window.location.reload();// 刷新当前页面
      }
      if (user_cache && user_cache.loginTime) {
        var loginTime = user_cache.loginTime
        console.log('loginTime===>', CommonUtils.dateFtt("yyyy-MM-dd hh:mm:ss", new Date(loginTime)))

        if ((new Date()).getTime() - loginTime >= 1000 * 60 * 60 * 24 * 7) {//7天登陆一次
          var url = window.location.origin + "/home?login=true";  // 必须重定向到/home， 在用户界面会报错
          window.open(url, "_self")
          // window.location.reload();// 刷新当前页面
        }
      }
    }

    if (isWeiXinLogin) {//等待微信登录成功
      setTimeout(() => {
        _this.getAgentDataFun()//查询上方卡片的数据
        _this.getLimitMoneyFun()//查询拆分额度
      }, 3000);
    } else {
      _this.getAgentDataFun()//查询上方卡片的数据
      _this.getLimitMoneyFun()//查询拆分额度
    }


  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }


  //查询上方卡片的数据
  getAgentDataFun = () => {
    let _this = this;
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (!user_cache) { return }//解决退出登录时还会访问的报错

    getAgentData().then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        // console.log("resData===>", resData)
        _this.setState({
          "baseCount": resData.baseCount,
          "recommendCount": resData.recommendCount,
          "shopCount": resData.shopCount,
          "earnMoney": resData.earnMoney,
          "needCashOut": resData.needCashOut,
          "checkMoney": resData.checkMoney,
          "hadCashOut": resData.hadCashOut,
          "lastUpdateTime": resData.lastUpdateTime,
        })
      }
    })
  }

  //查询拆分额度
  getLimitMoneyFun = () => {
    let _this = this;
    getLimitMoney({}).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          couponMoney_limit: resData.couponMoney
        })
      } else {
        _this.setState({
          couponMoney_limit: 0
        })
      }
    });
  }

  //查询代金券总和 statusType  1-待使用/待赠送； 2-已使用
  getSumOfCouponFun = (statusType) => {
    let _this = this;

    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    if (!user_cache) { return }//解决退出登录时还会访问的报错

    getSumOfCoupon({ statusType: statusType }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        if (statusType == 1) {
          _this.setState({
            couponTotal_noUse: resData
          })
        } else {
          _this.setState({
            couponTotal_used: resData
          })
        }
      } else {
        message.info("请求失败，请重试！")
      }
    });
  }


  render() {
    const { couponTotal_noUse, couponTotal_used, baseCount, recommendCount, shopCount, earnMoney, needCashOut, hadCashOut, checkMoney, lastUpdateTime,
      couponMoney_limit,
    } = this.state;
    var menu_items_user = CommonUtils.UI_Get_menu_items_user()

    var selectedKeys = ""
    var defaultOpenKeys = ""
    menu_items_user.map(i => {
      if (window.location.pathname.includes(i.myPath)) {
        selectedKeys = i.key
      } else if (i.children) {
        i.children.map(j => {
          if (window.location.pathname.includes(j.myPath)) {
            defaultOpenKeys = i.key
            selectedKeys = j.key
          }
        })
      }
    })

    var user = CommonUtils.getLocalStorage("user");//缓存用户信息

    // console.log("selectedKeys==>",selectedKeys)

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var menu_way = CommonUtils.get_menu_way(window.location.pathname)
    // console.log("menu_way==>", menu_way)

    return (
      <React.Fragment>
        <div className="rootBox userPageBox">

          <Row gutter={[16, 24]} justify="center" align="middle" style={{ marginBottom: "20px" }}>
            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="userPageCardBox">
                <div className="userPageCard">
                  <img className="userpageIcon" src="/img/pro/userCount.png" ></img>

                  <Row gutter={[16, 24]} justify="center" align="middle" className="userPageCardCol">
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <div className="userPageCardColBox">
                        <Tooltip title={"指您邀请用户的总数,更新时间：" + lastUpdateTime}>
                          <div>总客户</div>
                        </Tooltip>
                        <div className="userPageCardColNumBox"><span className="userPageCardColNum">{recommendCount}</span>个</div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <div className="userPageCardColBox">
                        <Tooltip title={"指从未购买店铺的客户数量,更新时间：" + lastUpdateTime}>
                          <div>待转化</div>
                        </Tooltip>
                        <div className="userPageCardColNumBox"><span className="userPageCardColNum">{baseCount}</span>个</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="userPageCardBox">
                <div className="userPageCard">
                  <img className="userpageIcon" src="/img/pro/orderCount.png" preview={false}></img>

                  <Row gutter={[16, 24]} justify="center" align="middle" className="userPageCardCol">
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <div className="userPageCardColBox">
                        <Tooltip title={"指您邀请的用户和优惠券订购店铺的总和,更新时间：" + lastUpdateTime}>
                          <div>订购量</div>
                        </Tooltip>

                        <div className="userPageCardColNumBox"><span className="userPageCardColNum">{shopCount}</span>店*月</div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >

                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col className="gutter-row" xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
              <div className="userPageCardBox">
                <div className="userPageCard">
                  <img className="userpageIcon" src="/img/pro/moneyCount.png" preview={false}></img>

                  <Row gutter={[16, 24]} justify="center" align="middle" className="userPageCardCol">
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                      <div className="userPageCardColBox">
                        <Tooltip title={"另外转化审核中:￥" + checkMoney + " 元,已经提现：" + hadCashOut + " 元,更新时间：" + lastUpdateTime}>
                          <div>总收益</div>
                        </Tooltip>
                        <div className="userPageCardColNumBox"><span className="userPageCardColNum">￥{needCashOut}</span>元</div>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                      <div className="userPageCardColBox">
                        {user && user.attr3 != "1" ? <>
                          <div>待提现</div>
                          <div className="userPageCardColNumBox"><span className="userPageCardColNum">￥{needCashOut - hadCashOut}</span>元</div>
                        </>
                          : <>
                            <Tooltip title={"点击去使用金币，兑换代金券"}>
                              <NavLink to="/user/agentExchange" >
                                <div>金币</div>
                                <div className="userPageCardColNumBox"><span className="userPageCardColNum">{couponMoney_limit}</span>个</div>
                              </NavLink>
                            </Tooltip>


                          </>}

                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>



          </Row>


          {/* 放总览信息  */}

          <Layout style={{ background: "none" }}>
            {
              isPhone ? null :
                <Sider width={200} >
                  <Menu
                    mode="inline"
                    selectedKeys={[selectedKeys]}
                    // defaultOpenKeys={[defaultOpenKeys]}//默认打开的父菜单
                    defaultOpenKeys={["代理赚钱", "店铺授权"]}//默认打开的父菜单
                    style={{
                      height: '100%',
                      minHeight: '100vh',
                      borderRight: "#f1f0f0 solid 1px !important"
                    }}
                    items={menu_items_user}
                  />
                </Sider>
            }

            {
              isPhone ?
                <Badge.Ribbon text="路径" color="#1daeff">
                  <div className="menu_way">{menu_way}</div>
                </Badge.Ribbon>

                : null
            }

            <Layout style={isPhone ? { padding: '22px', background: "#FFF", marginLeft: "0px" } : { padding: '22px', background: "#FFF", marginLeft: "28px" }} >
              <Content className="itemMain">
                {this.props.children}
              </Content>
            </Layout>
          </Layout>
        </div>
      </React.Fragment>
    );
  }
}

export default UserPage;