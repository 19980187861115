import React from "react";
import { addXpath, getXpath, deleteXpath, changeXpath } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import {
  Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space,
  message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, Result, Select,
} from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';


class XpathSetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paramsParams: {},//分页查询的参数缓存
      spinning: false,//是否为加载中状态
      tableData: [],
      showModel_addxpath: false,
      showModel_changexpath: false,
      changeData: null,
      showModel_showxpath: false,
      showxpathData: null,
    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    this.getXpathFun()

  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //跳转到另一个路由
  jumpToNewHash = (hashPath) => {
    this.props.history.push(hashPath);//跳转到新的路由
  }

  addxpathFun = (params) => {
    let _this = this;
    console.log("params ==>", params)

    addXpath(params).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          showModel_addxpath: false
        })
        _this.getXpathFun()//查询
        message.success("请求成功！")
      } else {
        _this.setState({
          tableData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！店铺名称不能有标点符号")
    })

  }

  getXpathFun = (params) => {
    let _this = this;
    console.log("params ==>", params)

    getXpath({}).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.map((t, i) => {
          t['index'] = i + 1
        })

        _this.setState({
          tableData: resData
        })
        message.success("请求成功！")
      } else {
        _this.setState({
          tableData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！店铺名称不能有标点符号")
    })

  }

  deleteXpathFun = (id) => {
    let _this = this;
    deleteXpath({ "id": id }).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.getXpathFun()//查询
        message.success("请求成功！")
      } else {
        _this.setState({
          tableData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！店铺名称不能有标点符号")
    })
  }

  changexpathState = (changeData) => {
    this.setState({
      showModel_changexpath: true,
      changeData: changeData
    })
  }

  changeXpathFun = (params) => {
    let _this = this;
    changeXpath(params).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          showModel_changexpath: false,
          changeData: null
        })
        _this.getXpathFun()//查询
        message.success("请求成功！")
      } else {
        _this.setState({
          tableData: [],
        })
      }
    }).catch(function (error) {//请求失败时
      message.info("创建失败，请重试！店铺名称不能有标点符号")
    })
  }


  render() {
    const { spinning, tableData, showModel_addxpath, showModel_changexpath, changeData,showModel_showxpath,showxpathData } = this.state;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: "50px",
        // render: (text) => <a>{text}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        width: "60px",
        // render: (text) => <a>{text}</a>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '平台',
        dataIndex: 'platform',
        ellipsis: true,
        width: "100px",
        key: 'platform',
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        width: "160px",
        ellipsis: true,
      },
      {
        title: '代码',
        dataIndex: 'code',
        key: 'code',
        ellipsis: true,
      },
      {
        title: '修改时间',
        dataIndex: 'lastUpdateTime',
        key: 'lastUpdateTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '操作',
        key: 'action',
        width: "148px",//只有文字才有用
        render: (_, record) => (
          <Space size="middle">
            <span className="colCardActionBtn" onClick={() => {
              this.setState({
                showModel_showxpath:true,
                showxpathData:record
              })
            }}>查看</span>
            <span className="colCardActionBtn" onClick={() => this.changexpathState(record)}>修改</span>
            <span className="colCardActionBtn" onClick={() => this.deleteXpathFun(record.id)}>删除</span>
          </Space>
        ),
      },
    ];



    return (
      <React.Fragment>
        <div style={{ margin: "10px 0px" }}>
          <Button type="primary" style={{ margin: "0px 10px" }} onClick={() => this.setStateKeyValue("showModel_addxpath", true)}>添加xPath</Button>
        </div>
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100, 300],
              showTotal: (total) => `共 ${total} 条`,
            }}
          />
        </Spin>

        <Modal title="添加xpath" open={showModel_addxpath} footer={null} onCancel={() => this.setStateKeyValue("showModel_addxpath", false)}>
          <Form labelCol={{ span: 5, }}
            wrapperCol={{ span: 19, }}
            style={{ maxWidth: 600, }}
            onFinish={this.addxpathFun}
            autoComplete="off"
          >
            <Form.Item label="平台" name="platform">
              <Select>
                <Select.Option value="1688">1688</Select.Option>
                <Select.Option value="拼多多">拼多多</Select.Option>
                <Select.Option value="拼多多批发">拼多多批发</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="名称" name="name"
              rules={[{
                required: true,
                message: '请输入名称!',
              },]} >
              <Input />
            </Form.Item>
            <Form.Item label="代码" name="code"
              rules={[{
                required: true,
                message: '请输入代码!',
              },]} >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
              <Button type="primary" htmlType="submit">添加xpath</Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal title="修改xpath" open={showModel_changexpath} footer={null}
          onCancel={() => {
            this.setState({
              showModel_changexpath: false,
              changeData: null
            })
          }}>
          {
            changeData ?
              <Form labelCol={{ span: 5, }}
                wrapperCol={{ span: 19, }}
                style={{ maxWidth: 600, }}
                onFinish={this.changeXpathFun}
                autoComplete="off"
                key={changeData.id}
              >
                <Form.Item label="id" name="id" initialValue={changeData ? changeData.id : null} >
                  <Input disabled value={changeData ? changeData.id : null} />
                </Form.Item>
                <Form.Item label="平台" name="platform" initialValue={changeData ? changeData.platform : null}>
                  <Select defaultValue={changeData ? changeData.platform : null}>
                    <Select.Option value="1688">1688</Select.Option>
                    <Select.Option value="拼多多">拼多多</Select.Option>
                    <Select.Option value="拼多多批发">拼多多批发</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="名称" name="name" initialValue={changeData ? changeData.name : null}
                  rules={[{
                    required: true,
                    message: '请输入名称!',
                  },]} >
                  <Input defaultValue={changeData ? changeData.name : null} />
                </Form.Item>
                <Form.Item label="代码" name="code" initialValue={changeData ? changeData.code : null}
                  rules={[{
                    required: true,
                    message: '请输入代码!',
                  },]} >
                  <Input defaultValue={changeData ? changeData.code : null} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
                  <Button type="primary" htmlType="submit">修改xpath</Button>
                </Form.Item>
              </Form>

              : null
          }

        </Modal>

        <Modal title="查看xpath" width={1200} open={showModel_showxpath} footer={null}
          onCancel={() => {
            this.setState({
              showModel_showxpath: false,
              showxpathData: null
            })
          }}>
          {
            showxpathData ?
              <Form labelCol={{ span: 2, }}
                wrapperCol={{ span: 22, }}
                style={{ maxWidth: 1200, }}
                autoComplete="off"
                key={showxpathData.id}
              >
                <Form.Item label="id" name="id" initialValue={showxpathData ? showxpathData.id : null} >
                  <Input disabled value={showxpathData ? showxpathData.id : null} />
                </Form.Item>
                <Form.Item label="平台" name="platform" initialValue={showxpathData ? showxpathData.platform : null}>
                  <Select disabled defaultValue={showxpathData ? showxpathData.platform : null}>
                    <Select.Option value="1688">1688</Select.Option>
                    <Select.Option value="拼多多">拼多多</Select.Option>
                    <Select.Option value="拼多多批发">拼多多批发</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item label="名称" name="name" initialValue={showxpathData ? showxpathData.name : null}
                  rules={[{
                    required: true,
                    message: '请输入名称!',
                  },]} >
                  <Input disabled defaultValue={showxpathData ? showxpathData.name : null} />
                </Form.Item>
                <Form.Item label="代码" name="code" initialValue={showxpathData ? showxpathData.code : null}
                  rules={[{
                    required: true,
                    message: '请输入代码!',
                  },]} >
                  <Input disabled defaultValue={showxpathData ? showxpathData.code : null} />
                </Form.Item>
              </Form>

              : null
          }

        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(XpathSetting);