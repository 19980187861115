import React from "react";
import { getCheckMoney, changeType,refreshStatisData } from "../http/api";
import CommonUtils from '../http/Common';
import { Typography, Divider, Button, Checkbox, Form, Input, Radio, Table, message, Spin } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';

//代理转化审核

class AgentCheckPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            payIdArray: [],
            params: {},
            spinning: false,//是否为加载中状态
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部

        // this.getCheckMoneyFun({ buyTime: 1, "startTime": dayjs().startOf('day').valueOf(), "endTime": dayjs().endOf('day').valueOf() });//获取审核列表
        this.getCheckMoneyFun({ buyTime: 1, "rangeTime": [dayjs().startOf('day').valueOf(), dayjs().endOf('day').valueOf()] });//获取审核列表
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //查询_分页
    getCheckMoneyFun_Page = (current, pageSize) => {
        var paramsParams = this.state.paramsParams
        paramsParams["current"] = current
        paramsParams["size"] = pageSize
        this.getCheckMoneyFun(paramsParams) //查询代理人拆分的代金券
    }

    //获取审核列表
    getCheckMoneyFun = (params) => {
        let _this = this;
        // console.log("params==>", params)

        if (params.otherKey) {
            if (params.otherKey.length == 11) {
                params["userPhone"] = params.otherKey
            } else {
                params["agentId"] = params.otherKey
            }
        }

        this.setState({
            paramsParams: params,
            spinning: true,
        }) //缓存参数，用于修改之后刷新

        // params["createdTime"] = params.startTime
        // params["lastUpdateTime"] = params.endTime
        getCheckMoney(params).then(res => {
            _this.setState({ spinning: false })
            if (res && res.data && res.data.success) {
                let resData = res.data.data;

                resData.map((t, i) => {
                    t['index'] = i + 1
                    t['key'] = t.id
                })

                _this.setState({
                    tableData: resData,
                    payIdArray: [],
                })
            } else {
                _this.setState({
                    tableData: [],
                    payIdArray: [],
                })
            }
        }).catch(function (error) {//请求失败时
            console.log(error)
            message.info("操作失败，请重试！")
        })
    }

    //修改审批状态
    changeTypeFun = (type) => {
        let _this = this;
        const { payIdArray } = this.state;
        if (payIdArray.length == 0) {
            message.info("请先选择！")
            return
        }
        changeType({ "type": type, "payIdArray": payIdArray }).then(res => {

            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.getCheckMoneyFun(_this.state.paramsParams) //获取审核列表
            }
        }).catch(function (error) {//请求失败时
            console.log(error)
            message.info("操作失败，请重试！")
        })
    }

    //修改状态后重新统计
    refreshStatisDataFun = ()=>{

        refreshStatisData({}).then(res=>{
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                message.info("重新统计完成！")
            }
        })
    }


    render() {
        const { tableData, payIdArray,spinning } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        const searchItems = [
            {
                "title": "状态类型", //状态类型   1-审核中  2-待转账  3-已提现
                "key": "buyTime",
                "type": "select",
                "defaultValue": true,//默认第一个值
                "options": [
                    { label: "审核中", value: 1 },
                    { label: "已审核", value: 2 },
                    // { label: "已提现", value: 3 },
                    { label: "已退款", value: 4 },
                    { label: "全部", value: "" },
                ]
            },
            // {
            //     "title": "开始时间",
            //     "key": "startTime",
            //     "type": "time",
            //     "placeholder": "时间",
            // },
            // {
            //     "title": "结束时间",
            //     "key": "endTime",
            //     "type": "time",
            //     "placeholder": "时间",
            // },
            {
                "title": "支付时间",
                "key": "rangeTime",
                "type": "rangeTime",
            },

            {
                "title": "代理/支付",
                "key": "otherKey",
                "type": "input",
                "placeholder": "代理人ID/支付人手机号码",
            },
        ]

        var columns_pay = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                width: 45,
            },
            {
                title: 'pay_id',
                dataIndex: 'id',
                key: 'id',
                width: 60,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '账户名称',
                dataIndex: 'userPhone',
                key: 'userPhone',
                width: 100,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '支付金额',
                dataIndex: 'payablePrice',
                key: 'payablePrice',
                render: (text) => <span>{text}元</span>,
            },
            {
                title: '代理总收益',
                dataIndex: 'totalMoney',
                key: 'totalMoney',
                render: (text) => <span>{text.toFixed(2)}元</span>,
            },
            {
                title: '提成占比',
                dataIndex: 'rate',
                key: 'rate',
                render: (text, record, index) => {
                    var rate = (record.totalMoney / record.payablePrice * 100).toFixed(2)
                    return <span style={rate > 50 ? { color: "red", fontWeight: 'bold' } : null}>{rate}%</span>
                },
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '数量',
                dataIndex: 'buyCount',
                key: 'buyCount',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '订购月数',
                dataIndex: 'buyTime',
                key: 'buyTime',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '平均单价',
                dataIndex: 'per',
                key: 'per',
                render: (text, record, index) => {
                    var perShopPrice = (record.payablePrice - record.totalMoney) / record.buyCount / record.buyTime
                    return <span>{perShopPrice.toFixed(2)}元</span>
                },
            },
            {
                title: '支付时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                width: 100,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '用户注册时间',
                dataIndex: 'userRegisterTime',
                key: 'userRegisterTime',
                ellipsis: true,
                width: 100,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
        ];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({
                    payIdArray: selectedRowKeys
                })
            },
            selections: [
                {
                    key: 'pass',
                    text: '审核通过',
                    onSelect: (changeableRowKeys) => {
                        console.log("审核通过")
                        this.changeTypeFun(2) // 修改审批状态 type;//状态类型   1-审核中  2-已审核  3-已提现

                    },
                },
                {
                    key: 'reCheck',
                    text: '设为审核中',
                    onSelect: (changeableRowKeys) => {
                        console.log("设为审核中")
                        this.changeTypeFun(1) // 修改审批状态 type;//状态类型   1-审核中  2-已审核  3-已提现
                    },
                },
                // {
                //     key: 'cashOut',
                //     text: '设为已提现',
                //     onSelect: (changeableRowKeys) => {
                //         let newSelectedRowKeys = [];
                //         console.log("设为已提现")
                //         this.changeTypeFun(3) // 修改审批状态 type;//状态类型   1-审核中  2-已审核  3-已提现
                //     },
                // },
                {
                    key: 'gitBack',
                    text: '设为已退款',
                    onSelect: (changeableRowKeys) => {
                        let newSelectedRowKeys = [];
                        console.log("设为已退款")
                        this.changeTypeFun(4) // 修改审批状态 type;//状态类型   1-审核中  2-已审核  3-已提现
                    },
                },
            ],
        };



        return (
            <React.Fragment>
                <div style={{marginBottom:"16px"}}>
                    <Button type="primary" onClick={this.refreshStatisDataFun} >修改状态后重新统计</Button>
                </div>
                <SearchItem searchFun={this.getCheckMoneyFun} searchItems={searchItems} />
                <Spin tip="查询中..." spinning={spinning}>
                    <Table columns={columns_pay} dataSource={tableData} size={isPhone ? "small" : "middle"}
                        pagination={{
                            position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 300, 500, 1000, 10000, 100000],
                            defaultPageSize:10000,
                            total: tableData.length, showTotal: (total) => `共 ${total} 条`,
                        }}
                        rowSelection={{ type: "checkbox", ...rowSelection, selectedRowKeys: payIdArray }}
                        summary={(pageData) => {
                            let payablePrice_count = 0;
                            let totalMoney_count = 0;
                            // console.log("pageData==>",pageData)
                            pageData.forEach(({ payablePrice, totalMoney }) => {
                                payablePrice_count += payablePrice;
                                totalMoney_count += totalMoney;
                            });
                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell ></Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={2} >本页合计</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={1}>{payablePrice_count}元</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={1}>{totalMoney_count.toFixed(2)}元</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={7}>总收益 {payablePrice_count - totalMoney_count.toFixed(2)} 元</Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        }}
                    />
                </Spin>
            </React.Fragment>
        );
    }
}

export default AgentCheckPage;