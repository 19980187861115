import React from "react";
import { distinguishBasic } from "../http/api";
import CommonUtils from '../http/Common';
import { Select, Divider, Button, Checkbox, DatePicker, Input, Radio, Space, Popconfirm, Tag, message, Card, Tooltip, Col, Row } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');  //国际化配置

//引用方式
// import SearchItem from "./pages/SearchItem.js";

// var searchItems = [{
//     "title": "有效时间",//标题
//     "key": "endTime",//state的key值
//     "type": "select",//类型 select  input
//     "defaultValue": true,//是否默认第一个值，将设置到state中
//      "tooltipText": "1、单券多次： 单个代金券可多次重复使用。\n2、多券单次：拆分多个代金券，每个代金券只能使用一次。",//提示
//     "options": [//选项
//     { label: "1天", value: "1天" },
//     { label: "3天", value: "3天" },
//     { label: "7天", value: "7天" },
//     { label: "1个月", value: "1个月" },
//     { label: "1个季度", value: "1个季度" },
//     { label: "半年", value: "半年" },
//     { label: "1年", value: "1年" },
//     ]
//   },]

// console.log(dayjs().startOf('day').valueOf())
// console.log(dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'))
// console.log(dayjs().endOf('day').valueOf())
// console.log(dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'))

class SearchItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // current: 1, // 默认不传,后台会自动赋值 current:1,size:10 
            // size: 10,
            data_reset: 1,//控制重置刷新的
            searchText: props.searchText ? props.searchText : "查 询"
        };
    }

    componentDidMount() {
        let _this = this;
        // CommonUtils.goToTop()//回到顶部

        this.props.searchItems.map((item, index) => {
            if (item.defaultValue) { //设置初始默认值state
                this.setStateKeyValue(item.key, item.options[0].value)
            }
            if (item.key == "startTime") {
                this.setStateKeyValue("startTime", dayjs().startOf('day').valueOf())
            }
            if (item.key == "endTime") {
                this.setStateKeyValue("endTime", dayjs().endOf('day').valueOf())
            }
            // if (item.key == "rangeTime") {//默认不传时间范围,传了,查询会很慢
            //     this.setStateKeyValue("rangeTime", [dayjs().startOf('year').valueOf(), dayjs().endOf('day').valueOf()])
            // }
        })
    }

    setStateKeyValue = (key, value) => {
        if(value == "全部"){
            this.setState({ [key]: undefined }); // 清空特定属性的值
            return
        }
        this.setState({
            [key]: value
        })
    }

    //搜索
    searchFunction = () => {
        // console.clear()
        const { rangeTime } = this.state;
        if (rangeTime) {
            console.log(dayjs(rangeTime[0]).format('YYYY-MM-DD HH:mm:ss'), " --> ", dayjs(rangeTime[1]).format('YYYY-MM-DD HH:mm:ss'))
        }

        this.props.searchFun(JSON.parse(JSON.stringify(this.state)));
    }

    //重置
    resetFunction = () => {
        this.props.searchItems.map(item => {
            // this.setState({ [item.key]: undefined }); // 清空特定属性的值
            if (item.defaultValue) { //设置初始默认值state
                this.setStateKeyValue(item.key, item.options[0].value)
            } else {
                this.setState({ [item.key]: undefined }); // 清空特定属性的值
            }

            if (item.key == "startTime") {
                this.setStateKeyValue("startTime", dayjs().startOf('day').valueOf())
            }
            if (item.key == "endTime") {
                this.setStateKeyValue("endTime", dayjs().endOf('day').valueOf())
            }
            // if (item.key == "rangeTime") {
            //     this.setStateKeyValue("rangeTime", [dayjs().startOf('year').valueOf(), dayjs().endOf('day').valueOf()])
            // }
        })
        this.setState({
            data_reset: (new Date()).getTime()
        }, () => this.searchFunction()) // 重新渲染   //重新搜索

    }


    render() {
        const { data_reset, searchText } = this.state;

        const rangePresets = [
            {
                label: '今天',
                value: [dayjs().startOf('day'), dayjs().endOf('day')],
            },
            {
                label: '本周',
                value: [dayjs().startOf('week'), dayjs().endOf('week')],
            },
            {
                label: '本月',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
            {
                label: '今年',
                value: [dayjs().startOf('year'), dayjs().endOf('year')],
            },
        ];

        return (
            <React.Fragment>
                <div style={{ marginBottom: "20px" }}>
                    <Row justify={"left"} align={"middle"} wrap>
                        {
                            this.props.searchItems && this.props.searchItems.length > 0 ?
                                this.props.searchItems.map((item, index) => {
                                    return (
                                        <Col xs={20} sm={10} md={3} lg={3} xl={3} xxl={3} style={item.type == "rangeTime" ? { minWidth: "288px", margin: "5px 10px" } : { minWidth: "250px", margin: "5px 10px" }} key={index}>
                                            {
                                                item.tooltipText ?
                                                    <Popconfirm
                                                        title="说明"
                                                        description={item.tooltipText}
                                                        showCancel={false}
                                                        icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                                                        style={{ maxWidth: "200px" }}
                                                    >
                                                        <span style={{ minWidth: "66px", display: "inline-block", cursor: 'pointer', fontSize: "12px" }}>{item.title}：</span>
                                                    </Popconfirm>
                                                    :
                                                    <span style={{ minWidth: "66px", display: "inline-block", fontSize: "12px" }}>{item.title}：</span>
                                            }



                                            {
                                                //下拉框
                                                item.type == "select" ?
                                                    <Select defaultValue={item.options[0].value} style={{ width: "180px", }}
                                                        onChange={(value) => this.setStateKeyValue(item.key, value)}
                                                        options={item.options}
                                                        key={data_reset} />
                                                    : null
                                            }
                                            {
                                                //输入框
                                                item.type == "input" ?
                                                    <Input style={{ width: "180px", }}
                                                        onChange={(value) => this.setStateKeyValue(item.key, value.target.value.trim())}
                                                        placeholder={item.placeholder}
                                                        type={item.inputType ? item.inputType : null}
                                                        key={data_reset} />
                                                    : null
                                            }
                                            {
                                                //时间选择器
                                                item.type == "time" ?
                                                    <DatePicker defaultValue={item.key == "startTime" ? dayjs().startOf('day') : dayjs().endOf('day')}
                                                        onChange={(date, dateString) => this.setStateKeyValue(item.key, date.valueOf())}
                                                        style={{ width: '178px' }} showTime={{ format: 'HH:mm', }} format="YYYY-MM-DD HH:mm"
                                                        key={data_reset} />
                                                    : null
                                            }
                                            {
                                                //时间范围
                                                item.type == "rangeTime" ?
                                                    <DatePicker.RangePicker presets={rangePresets} minDate={dayjs('2024-01-01')}
                                                        // defaultValue={[dayjs().startOf('year'), dayjs().endOf('day')]}
                                                        allowClear={false}
                                                        onChange={(dates, dateString) => this.setStateKeyValue(item.key, [dates[0].valueOf(), dates[1].endOf('day').valueOf()])}
                                                        style={{ width: '218px' }} format="YYYY-MM-DD"
                                                        key={data_reset} />
                                                    : null
                                            }

                                        </Col>
                                    )

                                })
                                : null
                        }



                        <Col xs={20} sm={10} md={3} lg={3} xl={3} xxl={3} style={{ minWidth: "150px" }}>
                            <Button style={{ marginRight: "10px" }} onClick={this.searchFunction}>{searchText}</Button>
                            <Button style={{ marginRight: "10px" }} onClick={this.resetFunction}>重  置</Button>

                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default SearchItem;