import React from "react";
import { Row, Col, Image, } from "antd"
import CommonUtils from '../../http/Common.js';

// 展示左边文字右边图片
class FunctionCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        };
    }

    render() {
        const { data } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()
        return (
            <React.Fragment>
                <div >
                    <Row gutter={[{ xs: 8, sm: 16, md: 24 }, { xs: 8, sm: 16, md: 24 }]} justify={"center"} align={"middle"} style={{ padding: "0px 0px 20px 0px" }}>
                        {
                            data.map((item, index) => {

                                return <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={6} >
                                    <div>
                                        <div className="FunctionCardBox">
                                            <div className="FunctionCardTop">
                                                <div className="FunctionCardTitle">{item.title}</div>
                                                <div className="FunctionCardImg">
                                                    <Image src={item.img} preview={false} width={61}></Image>
                                                </div>
                                            </div>
                                            <div className="FunctionCardBottom">
                                                <div className="FunctionCardText">{item.text1}</div>
                                                <div className="FunctionCardText">{item.text2}</div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            })
                        }

                    </Row>
                </div>

                {/* {this.props.children} */}
            </React.Fragment>
        );
    }
}

export default FunctionCard;