import React from "react";
import { splitCoupon, getLimitMoney, getAgentCouponList, getRecommendList, getPayList, getChangeList, } from "../http/api";
import CommonUtils from '../http/Common';
import { Divider, Badge, Button, QRCode, Popover, Row, Col, Input, Modal, Table, Tabs, message, Typography, Popconfirm, Tooltip } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import ShareCard from './ShareImage.js';


class AgentPage extends React.Component {
  constructor(props) {
    super(props);
    // this.image = new Image();
    // this.image.src = require("../img/advertising.jpg"); // 先下载下来缓存起来，避免使用的地方卡住
    this.state = {
      tableData_Coupon_split: [],//拆分代金券
      backgroundImg: require("../img/advertising.jpg"),
      showModel_shareCard: false,
      qrCodeValue: "",
      writeText: "",
      couponMoney_limit: 0,//代金券可拆分额度

      agentCouponList_total: 0,
      agentCouponList_tabelData: [],

      recommend_total: 0,
      recommendList: [],

      payList: [],
      payList_total: 0,

      changeList: [],
      changeList_total: 0,

    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    this.getLimitMoneyFun()//查询拆分额度
    this.getAgentCouponListFun() //查询代理人拆分的代金券

    this.getRecommendListFun(1, 10)//查询邀请记录
    this.getPayListFun(1, 10)//查询支付记录
    this.getChangeListFun(1, 10)//查询转化记录



  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //查询转化记录
  getChangeListFun = (current, pageSize) => {

    let _this = this;
    getChangeList({ "current": current, "size": pageSize }).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          changeList: resData.records,
          changeList_total: resData.total,
        })
      } else {
        _this.setState({
          changeList: [],
          changeList_total: 0,
        })
      }
    });
  }

  //查询拆分额度
  getLimitMoneyFun = () => {
    let _this = this;
    getLimitMoney({}).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          couponMoney_limit: resData.couponMoney
        })
      } else {
        _this.setState({
          couponMoney_limit: 0
        })
      }
    });
  }

  //查询代理人拆分的代金券
  getAgentCouponListFun = (params) => {
    var user = CommonUtils.getLocalStorage("user")
    let _this = this;
    if (!params) {
      params = {}
    }
    getAgentCouponList(params).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['url'] = window.location.origin + "/home?register=1&recommend=" + user.userId + "&couponCode=" + t.couponCode // 邀请链接
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        // console.log("resData >>>>>",resData)

        _this.setState({
          agentCouponList_total: resData.total,
          agentCouponList_tabelData: resData.records,
        })
        // message.info("查询成功！")
      } else {
        _this.setState({
          agentCouponList_total: 0,
          agentCouponList_tabelData: [],
        })
      }
    });
  }

  //分页查询代理人拆分的代金券
  agentCouponPageChangeFun = (current, pageSize) => {
    this.getAgentCouponListFun({ "current": current, "size": pageSize }) //查询代理人拆分的代金券
  }

  //拆分代金券
  searchFun = (params) => {
    const { couponMoney_limit } = this.state;
    let _this = this;

    // console.log("params==>", params)

    var couponCount = params.couponCount
    var couponMoney = params.couponMoney
    couponCount = parseInt(couponCount)
    couponMoney = parseInt(couponMoney)
    if (couponMoney * couponCount > couponMoney_limit) {//  1000从数据库获取
      console.log("所需额度超过可拆分额度，请降低代金券数量！")
      message.info("所需额度超过可拆分额度，请降低代金券数量！")
      return
    }

    splitCoupon(params).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        message.info("拆分成功！")
        this.getLimitMoneyFun()//查询拆分额度
        this.getAgentCouponListFun() //查询代理人拆分的代金券

      } else {
        message.info("请求失败，请重试！")
      }
    });

  }

  //增加拆分额度
  addLimitMoneyFun = () => {
    console.log("addLimitMoneyFun")
  }


  //表格的生成图片按钮
  makeImageFun = (record) => {
    this.setState({
      showModel_shareCard: true,
      qrCodeValue: record.url,
      writeText: record.couponCode,
    })
  }

  //查询邀请记录
  getRecommendListFun = (current, pageSize) => {
    let _this = this;

    getRecommendList({ "current": current, "size": pageSize }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          recommendList: resData.records,
          recommend_total: resData.total,
        })

      } else {
        _this.setState({
          recommendList: [],
          recommend_total: 0,
        })
        message.info("请求失败，请重试！")
      }
    });

  }

  //查询支付记录
  getPayListFun = (current, pageSize) => {
    let _this = this;

    getPayList({ "current": current, "size": pageSize }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          payList: resData.records,
          payList_total: resData.total,
        })

      } else {
        _this.setState({
          payList: [],
          payList_total: 0,
        })
        message.info("请求失败，请重试！")
      }
    });

  }

  render() {
    const { backgroundImg, showModel_shareCard, qrCodeValue, writeText, couponMoney_limit,
      agentCouponList_total, agentCouponList_tabelData, recommendList, recommend_total, payList, payList_total, changeList, changeList_total } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    var user = CommonUtils.getLocalStorage("user")
    if (!user) {
      window.location.href = window.location.origin + "/home?login=true";  // 必须重定向到/home， 在用户界面会报错
      return
    }
    const invite_url = window.location.origin + "/home?register=1&recommend=" + user.userId // 邀请链接

    // //邀请记录
    // var columns_recommend_t = [
    //   {
    //     title: '序号',
    //     dataIndex: 'index',
    //     key: 'index',
    //     // render: (text) => <a>{text}</a>,
    //   },
    //   {
    //     title: '账户名称',
    //     dataIndex: 'phone',
    //     key: 'phone',
    //   },
    //   {
    //     title: '提成比例',
    //     dataIndex: 'rate',
    //     key: 'rate',
    //   },
    //   {
    //     title: '代理收益(￥)',
    //     dataIndex: 'money',
    //     key: 'money',
    //   },
    //   {
    //     title: '是否转化',
    //     dataIndex: 'hasChange',
    //     key: 'hasChange',
    //   },
    //   {
    //     title: '转化时间',
    //     dataIndex: 'changeTime',
    //     key: 'changeTime',
    //     ellipsis: true,
    //   },
    //   {
    //     title: '操作',
    //     key: 'action',
    //     render: (_, record) => {
    //       // console.log(record)
    //       return <Space size="middle">
    //         <a className="colCardActionBtn" onClick={() => this.deleteShopFun(record.shopId)}>删除</a>
    //       </Space>
    //     },
    //   },
    // ];


    //邀请记录
    var columns_recommend = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: '邀请用户',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: '注册时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
      },
    ];

    var columns_pay = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: '账户名称',
        dataIndex: 'userPhone',
        key: 'userPhone',
      },
      {
        title: '收益类型',
        dataIndex: 'discountCode',
        key: 'discountCode',
      },
      {
        title: '代金券价值',
        dataIndex: 'disCountValue',
        key: 'disCountValue',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '支付金额',
        dataIndex: 'payablePrice',
        key: 'payablePrice',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '支付时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
      },
      {
        title: '用户注册时间',
        dataIndex: 'userRegisterTime',
        key: 'userRegisterTime',
        ellipsis: true,
      },
      {
        title: '提成比例',
        dataIndex: 'disCountValue',
        key: 'disCountValue',
        render: (text) => <span>{text * 100}%</span>,
      },
      {
        title: '代理收益（¥）',
        dataIndex: 'disCountValue',
        key: 'disCountValue',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '是否提现',
        dataIndex: 'isCashOut',
        key: 'isCashOut',
        render: (isCashOut) => {
          //是否提现  0-未提现  1-审核中  2-已经提现
          if (isCashOut == 2) {
            return <Badge count={"已提现"} style={{ backgroundColor: '#52c41a', }} />
          } else if (isCashOut == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#d3d3d3', }} />
          } else if (isCashOut == 0) {
            return <Badge count={"未提现"} style={{ backgroundColor: '#d3d3d3', }} />
          }

        },
      },
      {
        title: '提现时间',
        dataIndex: 'cashOutTime',
        key: 'cashOutTime',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
    ];

    var columns_change = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: '账户名称',
        dataIndex: 'buyerPhone',
        key: 'buyerPhone',
      },
      {
        title: '订单号',
        dataIndex: 'payId',
        key: 'payId',
      },
      {
        title: '收益类型',
        dataIndex: 'couponCode',
        key: 'couponCode',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text) => {
          if (text) {
            return <Tooltip title={"用户使用了您赠送的代金券产生的收益"}>
              <span>{text}</span>
            </Tooltip>

          } else {
            return <span>推荐人收益</span>
          }
        },
      },
      // {
      //   title: '代金券价值',
      //   dataIndex: 'disCountValue',
      //   key: 'disCountValue',
      //   render: (text) => <span>{text}元</span>,
      // },
      {
        title: '平均单价(店/月)',
        dataIndex: 'eachShopMonthPay',
        key: 'eachShopMonthPay',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '关联数量(店*月)',
        dataIndex: 'discountShopCount',
        key: 'discountShopCount',
        render: (text) => <span>{text}</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      // {
      //   title: '总分成比例',//不限制，避免解释成本
      //   dataIndex: 'rate',
      //   key: 'rate',
      //   render: (text) => <span>{text * 100}%</span>,
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      // },
      {
        title: '代理收益（¥）',
        dataIndex: 'earnMoney',
        key: 'earnMoney',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '状态',
        dataIndex: 'type',
        key: 'type',
        render: (type) => {
          //是否提现  1-审核中  2-待转账  3-已提现
          if (type == 2) {
            return <Badge count={"待转账"} style={{ backgroundColor: '#52c41a', }} />
          } else if (type == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#d3d3d3', }} />
          } else if (type == 3) {
            return <Badge count={"已提现"} style={{ backgroundColor: '#52c41a', }} />
          }

        },
      },
      {
        title: '支付时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
      {
        title: '提现时间',
        dataIndex: 'cashOutTime',
        key: 'cashOutTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
    ];


    var columns_Coupon_split = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: '代理人ID',
        dataIndex: 'agentUserId',
        key: 'agentUserId',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '验证码',
        dataIndex: 'couponCode',
        key: 'couponCode',
      },
      {
        title: '类型',
        dataIndex: 'couponType',
        key: 'couponType',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '有效时间',
        dataIndex: 'validTime',
        key: 'validTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '金额',
        dataIndex: 'couponMoney',
        key: 'couponMoney',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '数量',
        dataIndex: 'couponCount',
        key: 'couponCount',
        render: (text) => <span>{text}张</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '已领取',
        dataIndex: 'receiveCount',
        key: 'receiveCount',
        render: (text) => <span>{text}张</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '是否使用',
        dataIndex: 'statusType',
        key: 'statusType',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text) => {
          if (text == 2) {
            return <Badge count={"已使用"} style={{ backgroundColor: '#52c41a', }} />
          } else {
            return <Badge count={"未使用"} style={{ backgroundColor: '#d3d3d3', }} />
          }

        },
      },
      {
        title: '代金券网址',
        dataIndex: 'url',
        key: 'url',
        ellipsis: true,
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text, record, index) => {
          return <Typography.Paragraph ellipsis copyable={{ text: text }} style={{ margin: "0px" }}> {text} </Typography.Paragraph>
        },
      },
      {
        title: '二维码',
        dataIndex: 'qrcode',
        key: 'qrcode',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text, record, index) => {
          return <Popover overlayInnerStyle={{ padding: 0, }} content={<QRCode value={record.url} bordered={false} />} >
            <QrcodeOutlined />
          </Popover>
        },
      },
      {
        title: '生成图片',
        dataIndex: 'makeImage',
        key: 'makeImage',
        render: (text, record, index) => {
          return <Button size="small" onClick={() => this.makeImageFun(record)}>生成图片</Button>
        },
      },
      {
        title: '过期时间',
        dataIndex: 'endTimeFormat',
        key: 'endTimeFormat',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
      // {
      //   title: '操作',
      //   key: 'action',
      //   render: (_, record) => {
      //     // console.log(record)
      //     return  <Space size="middle">
      //     <a className="colCardActionBtn" onClick={()=>this.deleteShopFun(record.shopId)}>删除</a>
      //   </Space>
      //   },
      // },
    ];




    const searchItems = [
      // {
      //   "title": "代金券类型",
      //   "key": "couponType",
      //   "type": "select",
      //   "defaultValue": true,
      //   "tooltipText": "1、单券多次： 单个代金券可多次重复使用。2、多券单次：拆分多个代金券，每个代金券只能使用一次。",
      //   "options": [
      //     { label: "单券多次", value: "单券多次" },
      //     { label: "多券单次", value: "多券单次" },
      //   ]
      // },
      // {
      //   "title": "有效时间",
      //   "key": "validTime",
      //   "type": "select",
      //   "defaultValue": true,
      //   "tooltipText": "当代金券过期后未被使用，对应的代金券额度将会重新叠加到可拆分额度内。",
      //   "options": [
      //     { label: "1天", value: "1天" },
      //     { label: "3天", value: "3天" },
      //     { label: "1周", value: "1周" },
      //     { label: "1个月", value: "1个月" },
      //     { label: "1个季度", value: "1个季度" },
      //     { label: "半年", value: "半年" },
      //     { label: "1年", value: "1年" },
      //   ]
      // },
      // {
      //   "title": "代金券金额",
      //   "key": "couponMoney",
      //   "type": "input",
      //   "inputType": "number",
      //   "placeholder": "",
      //   "tooltipText": "每个代金券的金额",
      //   "tooltipText": "代金券金额 * 代金券数量= 总拆分额度 （剩余的将继续保留在代金券可拆分额度中）",
      // },
      // {
      //   "title": "代金券数量",
      //   "key": "couponCount",
      //   "type": "input",
      //   "inputType": "number",
      //   "placeholder": "",
      //   "tooltipText": "代金券金额 * 代金券数量= 总拆分额度 （剩余的将继续保留在代金券可拆分额度中）",
      // },
      {
        "title": "状态",
        "key": "type",
        "type": "select",
        "defaultValue": true,
        "tooltipText": "1",
        "options": [
          { label: "全部", value: "全部" },
          { label: "已领取部分", value: "已领取部分" },
          { label: "已领取全部", value: "已领取全部" },
          { label: "已使用部分", value: "已使用部分" },
          { label: "已使用全部", value: "已使用全部" },
          { label: "未领取", value: "未领取" },
          { label: "已过期", value: "已过期" },
        ]
      },
      {
        "title": "创建时间",
        "key": "rangeTime",
        "type": "rangeTime",
        "placeholder": "时间",
      },

    ]

    const searchItems_invite = [
      {
        "title": "注册时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
    ]

    const searchItems_change = [
      {
        "title": "状态",
        "key": "type",
        "type": "select",
        "defaultValue": true,
        "tooltipText": "1",
        "options": [
          { label: "全部", value: "全部" },
          { label: "已领取部分", value: "已领取部分" },
          { label: "已领取全部", value: "已领取全部" },
          { label: "已使用部分", value: "已使用部分" },
          { label: "已使用全部", value: "已使用全部" },
          { label: "未领取", value: "未领取" },
          { label: "已过期", value: "已过期" },
        ]
      },
      {
        "title": "支付时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
    ]



    return (
      <React.Fragment>
        <Tabs
          type="card"
          size={isPhone ? "small" : "middle"} //large  middle   small  
          items={[
            {
              label: "邀请好友",
              key: 1,
              children: <>
                <div className="agentInviteHeader">
                  <div className="agentInviteHeaderLogo">
                    <img className="agentInviteHeaderLogoImg" src="/img/logo.png" preview={false}></img>
                    爆单ERP
                  </div>
                  <div className="agentInviteHeaderText">
                    邀请越多人加入爆单ERP，赚取越多收益！
                  </div>
                  <img className="agentPagePeople" src="/img/pro/agentPeople.png" preview={false}></img>
                </div>
                <div className="agentInviteTextBox">
                  <div style={{ color: "#666666", fontSize: "14px", marginBottom: "61px" }}>邀请好友（客户）加入爆单ERP平台可获取以下三种权益</div>

                  <Row gutter={[16, 24]} justify={"left"} align={"middle"}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
                      <div style={{ textAlign: "center" }}>
                        <img className="agentIcon" src="/img/pro/agentIcon1.png" ></img>
                        <div className="inviteTitle">按比例提成客户消费额度</div>
                        <div className="inviteText">首月按50%提成客户消费额度</div>
                        <div className="inviteText">终身按20%提成客户消费额度</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
                      <div style={{ textAlign: "center" }}>
                        <img className="agentIcon" src="/img/pro/agentIcon2.png" ></img>
                        <div className="inviteTitle">获赠100金币、100代金券</div>
                        <div className="inviteText">邀请新用户完成注册，代理人获得</div>
                        <div className="inviteText">100金币、100元代金券</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}  >
                      <div style={{ textAlign: "center" }}>
                        <img className="agentIcon" src="/img/pro/agentIcon3.png" ></img>
                        <div className="inviteTitle">按比例提成客户消费代金券额度</div>
                        <div className="inviteText">客户通过您赠送的代金券消费您可</div>
                        <div className="inviteText">获得相应比例的现金提成</div>
                      </div>
                    </Col>
                  </Row>
                  <div className="agentInviteBtnBox">
                    <Button style={{ background: "#0766F4", color: "#FFF", borderRadius: "2px", marginRight: "20px" }}>立即邀请</Button>
                    <Button style={{ background: "#F92D2D", color: "#FFF", borderRadius: "2px" }}>赠送代金券</Button>
                  </div>

                </div>


                {/* <div>
                  <Popover overlayInnerStyle={{ padding: 0, }}
                    content={<QRCode value={invite_url} bordered={false} />}
                  >
                    <Typography.Paragraph ellipsis style={{ maxWidth: "60vw" }} copyable={{ text: invite_url }}> {"邀请URL：" + invite_url} </Typography.Paragraph>
                  </Popover>
                </div>
                <span>邀请图片：</span>
                <ShareCard
                  backgroundImg={backgroundImg}
                  qrCodeValue={invite_url}
                  // writeText={writeText}
                  key={invite_url}
                /> */}

              </>,
            },
            {
              label: "兑换代金券",
              key: 2,
              children: <>
                <div className="exchangeCouponHeader"  style={isPhone ? {lineHeight:"50px",padding:"15px"} : {lineHeight: "80px"}}>
                  <Row justify={"left"} align={"middle"}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4} style={isPhone ? {textAlign:"left"} : {textAlign:"center"}} >
                        <Button>兑换代金券</Button>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}  >
                      <Popconfirm
                        title="说明"
                        description={"我的金币每个月1号刷新一次，低于1000个的，将重新赋予1000个金币。高于1000个的不变。"}
                        showCancel={false}
                        icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                        style={{ maxWidth: "200px" }}
                      >
                        <div>
                          <div className="exchangeCouponMy">我的金币：{couponMoney_limit}个</div>
                          <div className="exchangeCouponDetailText">1000金币可兑换1000元代金券</div>
                        </div>
                      </Popconfirm>


                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}  >
                      <div>
                        <Button onClick={this.addLimitMoneyFun}>充值</Button>
                        <span className="exchangeCouponBuyText">50元人民币充值1000金币</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}  >
                      <div>
                        <Button>邀请好友</Button>
                        <span className="exchangeCouponBuyText">邀请一个新客户注册获赠100金币</span>
                      </div>
                    </Col>

                  </Row>
                </div>

                <SearchItem searchFun={this.searchFun} searchItems={searchItems} searchText="查询" />
                <Table columns={columns_Coupon_split} dataSource={agentCouponList_tabelData} size={isPhone ? "small" : "middle"}
                  pagination={{
                    position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
                    total: agentCouponList_total, showTotal: (total) => `共 ${total} 条`,
                    onChange: this.agentCouponPageChangeFun
                  }} />

              </>,
            },
            {
              label: "邀请记录",
              key: 3,
              children: <>

                <SearchItem searchFun={this.getRecommendListFun} searchItems={searchItems_invite} />
                <Table columns={columns_recommend} dataSource={recommendList} size={isPhone ? "small" : "middle"}
                  pagination={{
                    position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
                    total: recommend_total, showTotal: (total) => `共 ${total} 条`,
                    onChange: this.getRecommendListFun
                  }} />
              </>,
            },
            {
              label: "转化记录",
              key: 4,
              children: <>

                <SearchItem searchFun={this.getRecommendListFun} searchItems={searchItems_change} />
                <Table columns={columns_change} dataSource={changeList} size={isPhone ? "small" : "middle"}
                  pagination={{
                    position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
                    total: changeList_total, showTotal: (total) => `共 ${total} 条`,
                    onChange: this.getChangeListFun
                  }} />

                <br /><br /><br />
                <Divider id='part-permissions'>说明</Divider>
                <br />
                <Typography.Text >
                  1、代理收益 = 平均单价 * 关联数量 * (1-0.03) * 总分成比例 * 0.5。<br />
                  解释：<br />
                  关联数量 = 订购店铺数 * 订购月份<br />
                  0.03： 增值税销售额500万元及以下的小规模纳税人的软件服务税率则为3%，根据计算公式，平台和代理人各承担一半税收。 <br />
                  总分成比例：每一单消费的消费者，注册时间在一个月内的，总分成为50%；超过一个月的为30%；超过一个月，不到一年的总分成比例为30%;超过一年的总分成比例为20%。 <br />
                  0.5：	邀请新用户和分发代金券的代理人评分代理收益。如果邀请新用户和代金券分发的均为同一账户，则代理收益全部归同一账户所得，邀请新用户和代金券收益是分开记录的，总额不变。<br />
                  另： 计算原子为1个店铺1个月，如果部分店铺未使用到代金券，则该部分的店铺对应的代理收益，全归邀请新用户的代理人。<br />
                  <br />
                </Typography.Text>

                <Typography.Text strong>
                  为了获得最大的代理收益，建议使用代金券邀请用户，尽量做到邀请新用户和分发代金券的代理人都是自己，这样可以拿到最多的代理收益。同时邀请用户在一个月内消费，总分成比例为50%。<br /><br />
                </Typography.Text>
                <Typography.Text >
                  2、审核时间：1~7个工作日。
                </Typography.Text>
              </>,
            },
            {
              label: "提现记录",
              key: 5,
              children: <>

                {/* <SearchItem searchFun={this.getRecommendListFun} searchItems={searchItems_invite} /> */}
                <Table columns={columns_pay} dataSource={payList} size={isPhone ? "small" : "middle"}
                  pagination={{
                    position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
                    total: payList_total, showTotal: (total) => `共 ${total} 条`,
                    onChange: this.getPayListFun
                  }} />
              </>,
            },
          ]}
        />


        <Modal title="代金券二维码宣传图" open={showModel_shareCard} footer={null} onCancel={() => this.setStateKeyValue("showModel_shareCard", false)}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <ShareCard
              backgroundImg={backgroundImg}
              qrCodeValue={qrCodeValue}
              writeText={writeText}
              key={writeText}
            />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AgentPage;