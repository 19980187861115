import React from "react";
import { getBadToken, getResendCodeUrl, } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Form, Flex, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin,Tag, } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';


class ShopResendCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            tableData: [],
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部
        this.getBadTokenFun()

    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    //获取刷新失败的数据
    getBadTokenFun = () => {
        let _this = this;
        getBadToken({}).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                resData.map((t, i) => {
                    t['index'] = i + 1
                  })
                _this.setState({
                    tableData: resData
                })
            } else {
                message.info("请求失败，请重试！")
            }
        });
    }

    //刷新失败的,重新授权
    //回访的链接: http://localhost:3000/user/authshopee?code=resend7046536f44506861785552584c487569
    getResendCodeUrlFun = (record) => {
        let _this = this;
        var params = {}
        if(record && record.merchant_id && record.shopId && record.merchant_id == record.shopId){
            params = {"mainAccountId": record.merchant_id }
            CommonUtils.copyData(record.merchant_id)
        }else{
            params = {"shopId": record.shopId}
            CommonUtils.copyData(record.shopId)
        }

        getResendCodeUrl(params).then(res => {
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                window.open(resData, "_blank")
            } else {
                message.info("请求失败，请重试！")
            }
        });

    }

    render() {
        const { tableData, spinning } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        var columns = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '店铺ID',
                dataIndex: 'shopId',
                key: 'shopId',
                // render: (text) => <a>{text}</a>,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '店铺类型',
                dataIndex: 'shopType',
                key: 'shopType',
                render: (_, record) => {
                    if(record && record.merchant_id && record.shopId && record.merchant_id == record.shopId){
                        // return <span>商户</span>
                        return <Tag color="#2db7f5">商户</Tag>
                    }else{
                        return <span>单个店铺</span>
                    }
                },
            },
            {
                title: '店铺名称',
                dataIndex: 'shop_name',
                ellipsis: true,
                key: 'shop_name',
            },
            {
                title: '地区',
                dataIndex: 'regionName',
                key: 'regionName',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '店铺状态',
                dataIndex: 'platformStatus',
                key: 'platformStatus',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (text) => {
                    //BANNED, FROZEN, NORMAL.
                    if (text == "NORMAL") {
                        return <span>正常</span>
                    }
                    if (text == "FROZEN") {
                        return <span>冻结</span>
                    }
                    if (text == "BANNED") {
                        return <span>禁止</span>
                    }

                },
            },
            {
                title: '授权状态',
                dataIndex: 'authType',
                key: 'authType',
            },
            {
                title: '授权失效时间',
                dataIndex: 'expire_time',
                key: 'expire_time',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                ellipsis: true,
            },
            {
                title: '操作',
                key: 'action',
                width: "88px",//只有文字才有用
                render: (_, record) => (
                    <Space size="middle">
                        <span className="colCardActionBtn" onClick={()=>this.getResendCodeUrlFun(record)}>重新授权</span>
                    </Space>
                ),
            },
        ];

        return (
            <React.Fragment>
                <Spin tip="查询中..." spinning={spinning}>
                    <Table columns={columns} dataSource={tableData} size={isPhone ? "small" : "middle"}
                        pagination={{
                            position: ["bottomCenter"], showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            showTotal: (total) => `共 ${total} 条`,
                        }} />
                </Spin>
            </React.Fragment>
        );
    }
}

export default withRouter(ShopResendCode);