import React from "react";
import { getChangeList } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Form, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip, Spin } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import WechatJsCommon from './components/WechatJsCommon';
import dayjs from 'dayjs';


//转化记录

class AgentChangePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeList: [],
      changeList_total: 0,
      spinning: false,//是否为加载中状态
    };
  }

  componentDidMount() {
    let _this = this;

    // CommonUtils.goToTop()//回到顶部



    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()
      }, 3000);
    } else {
      _this.initFun()
    }

  }

  //初始化方法
  initFun = () => {
    let _this = this;
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getChangeListFun()//查询转化记录
  }


  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //查询转化记录_分页
  getChangeListFun_Page = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getChangeListFun(paramsParams) //查询代理人拆分的代金券
  }

  //查询转化记录
  getChangeListFun = (params) => {
    //默认不用时间范围,加上时间范围,查询太慢了(1分钟多).
    let _this = this;
    if (!params) {
      params = {}
    }

    this.setState({
      paramsParams: params,
      spinning: true,
    })

    getChangeList(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          changeList: resData.records,
          changeList_total: resData.total,
        })
      } else {
        _this.setState({
          changeList: [],
          changeList_total: 0,
        })
      }
    });
  }



  render() {
    const { changeList, changeList_total, spinning } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    const searchItems_change = [
      {
        "title": "状态",
        "key": "type",
        "type": "select",
        "defaultValue": false,
        // "tooltipText": "1",
        "options": [ //type;//状态类型   1-审核中  2-已审核  3-已提现  4-已退款
          { label: "全部", value: "全部" },
          { label: "审核中", value: "1" },
          { label: "已审核", value: "2" },
          // { label: "已提现", value: "3" },
          { label: "已退款", value: "4" },
        ]
      },
      {
        "title": "支付时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
    ]

    var columns_change = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '账户名称',
        dataIndex: 'buyerPhone',
        key: 'buyerPhone',
      },
      {
        title: '订单号',
        dataIndex: 'payId',
        key: 'payId',
      },
      {
        title: '收益类型',
        dataIndex: 'couponCode',
        key: 'couponCode',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        render: (text) => {
          if (text) {
            return <Tooltip title={"用户使用了您赠送的代金券产生的收益"}>
              <span>{text.split("-")[0]}</span>
            </Tooltip>

          } else {
            return <span>推荐人收益</span>
          }
        },
      },
      // {
      //   title: '代金券价值',
      //   dataIndex: 'disCountValue',
      //   key: 'disCountValue',
      //   render: (text) => <span>{text}元</span>,
      // },
      {
        title: '平均单价(店/月)',
        dataIndex: 'eachShopMonthPay',
        key: 'eachShopMonthPay',
        render: (text) => <span>{text}元</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '关联数量(店*月)',
        dataIndex: 'discountShopCount',
        key: 'discountShopCount',
        render: (text) => <span>{text}</span>,
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      // {
      //   title: '总分成比例',//不限制，避免解释成本
      //   dataIndex: 'rate',
      //   key: 'rate',
      //   render: (text) => <span>{text * 100}%</span>,
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      // },
      {
        title: '代理收益（¥）',
        dataIndex: 'earnMoney',
        key: 'earnMoney',
        render: (text) => <span>{text}元</span>,
      },
      {
        title: '状态',
        dataIndex: 'type',
        key: 'type',
        render: (type) => {
          //是否提现  1-审核中  2-已审核  4-已退款
          if (type == 2) {
            return <Badge count={"已审核"} style={{ backgroundColor: '#52c41a', }} />
          } else if (type == 1) {
            return <Badge count={"审核中"} style={{ backgroundColor: '#d3d3d3', }} />
          } else if (type == 4) {
            return <Badge count={"已退款"} style={{ backgroundColor: '#f00', }} />
          }

        },
      },
      {
        title: '支付时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
      {
        title: '提现时间',
        dataIndex: 'cashOutTime',
        key: 'cashOutTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
    ];

    return (
      <React.Fragment>
        <SearchItem searchFun={this.getChangeListFun} searchItems={searchItems_change} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns_change} dataSource={changeList} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
              total: changeList_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.getChangeListFun_Page
            }} />
        </Spin>

        <br /><br /><br />
        <Divider id='part-permissions'>说明</Divider>
        <br />
        <Typography.Text >
          1、代理收益 = 平均单价 * 关联数量 * 总分成比例 * 0.5。<br />
          解释：<br />
          关联数量 = 订购店铺数 * 订购月份<br />
          {/* 0.03： 增值税销售额500万元及以下的小规模纳税人的软件服务税率则为3%。 <br /> */}
          总分成比例：每一单消费的消费者，注册时间在一个月内的，总分成为50%；超过一个月的为30%；超过一个月，不到一年的总分成比例为30%;超过一年的总分成比例为20%。 <br />
          0.5：	邀请新用户和分发代金券的代理人评分代理收益。如果邀请新用户和代金券分发的均为同一账户，则代理收益全部归同一账户所得，邀请新用户和代金券收益是分开记录的，总额不变。<br />
          另： 计算原子为1个店铺1个月，如果部分店铺未使用到代金券，则该部分的店铺对应的代理收益，全归邀请新用户的代理人。<br />
          <br />
        </Typography.Text>

        <Typography.Text strong>
          为了获得最大的代理收益，建议使用代金券邀请用户，尽量做到邀请新用户和分发代金券的代理人都是自己，这样可以拿到最多的代理收益。同时邀请用户在一个月内消费，总分成比例为50%。<br /><br />
        </Typography.Text>
        <Typography.Text >
          2、审核时间：1~7个工作日。
        </Typography.Text>
      </React.Fragment>
    );
  }
}

export default AgentChangePage;