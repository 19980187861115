import React from "react";
import { getCouponList } from "../http/api";
import CommonUtils from '../http/Common';
import { Image, Divider, Button, Checkbox, Form, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import WechatJsCommon from './components/WechatJsCommon';


//判断是不是手机屏幕
var isPhone = CommonUtils.isPhoneClient()

class CouponExpirePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon_expire: [],//过期
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getCouponListFun("coupon_expire", 3)//查询代金券方法

    //  distinguishBasic({}).then(res=>{

    //     if(res && res.data && res.data.success){
    //            let resData = res.data.data;

    //            message.success("请求成功！")
    //      }else{
    //          _this.setState({
    //             tableData:[],
    //           })
    //        }
    //   }).catch(function (error) {//请求失败时
    //   message.info("创建失败，请重试！店铺名称不能有标点符号")
    // })
  }

  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }


  //查询代金券方法
  getCouponListFun = (key, statusType) => {
    let _this = this;
    if (!key || !statusType) {
      return
    }

    getCouponList({ "statusType": statusType }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          [key]: resData
        })

      } else {
        _this.setState({
          [key]: [],
        })
        message.info("请求失败，请重试！")
      }
    });
  }
  render() {
    const { coupon_expire } = this.state;



    return (
      <React.Fragment>
        <div style={{ textAlign: "center" }}>
          {
            coupon_expire.length > 0 ?
              coupon_expire.map((t, i) => {
                return (<div className="couponCardNew">
                  <div className="couponCardLeftNew">
                    代金券
                  </div>
                  <div className="couponCardContentNew">
                    <div className="couponCardTitleNew">
                      <span style={{ fontSize: "16px" }}>￥</span>
                      {t.couponMoney}
                      <span style={{ fontSize: "16px" }}>元</span>
                    </div>
                    <div style={{ color: "#FFF", fontSize: "12px" }}>{t.endTimeFormat.substring(0, 11)}过期</div>
                  </div>
                </div>);
              })
              :
              <div>
                <div>
                  <Image src="/img/pro/no_coupon.png" preview={false}></Image>
                </div>
                <div className="no_coupon_text">暂无代金券</div>
              </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default CouponExpirePage;