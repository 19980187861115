import http from './http'

/**
 * 这里统一处理项目中所有的 api 接口
 */


export const getCode = params => http.post('/service/public/getCode',params,false);
export const searchByWord = params => http.get('/service/public/search',params,false);
export const exportProData = params => http.export('/service/monitorBusiness/exportProData',params,false);
export const exportInitData = params => http.exportPost('/service/monitorBusiness/exportInitData',params,false);

export const register = params => http.post('/service/erp/user/public/register',params,false);
export const getcode = params => http.post('/service/erp/user/public/getcode',params,false);
export const login = params => http.post('/service/erp/user/public/login',params,false);
export const loginByToken = params => http.post('/service/erp/user/loginByToken',params,false);
export const getRecommendList = params => http.post('/service/erp/user/getRecommendList',params,false);
export const getShopAuthUrl = params => http.post('/service/erp/shopee/getShopAuthUrl',params,false);
export const getAccesstoken = params => http.post('/service/erp/shopee/getAccesstoken',params,false);
export const getResendCodeUrl = params => http.post('/service/erp/shopee/getResendCodeUrl',params,false);
export const getBadToken = params => http.post('/service/erp/shopee/getBadToken',params,false);
export const resendCode = params => http.post('/service/erp/shopee/resendCode',params,false);
export const getAllCategory = params => http.post('/service/erp/shopee/getAllCategory',params,false);
export const getPlatformShop = params => http.post('/service/erp/shop/getPlatformShop',params,false);
export const setShop = params => http.post('/service/erp/shop/setShop',params,false);
export const deleteShop = params => http.post('/service/erp/shop/deleteShop',params,false);
export const getChangeList = params => http.post('/service/erp/earn/getChangeList',params,false);
export const getCheckMoney = params => http.post('/service/erp/earn/getCheckMoney',params,false);
export const changeType = params => http.post('/service/erp/earn/changeType',params,false);
export const splitCoupon = params => http.post('/service/erp/coupon/splitCoupon',params,false);
export const getLimitMoney = params => http.post('/service/erp/coupon/getLimitMoney',params,false);
export const getAgentCouponList = params => http.post('/service/erp/coupon/getAgentCouponList',params,false);
export const getAgentLimit = params => http.post('/service/erp/coupon/getAgentLimit',params,false);
export const updateAgentLimit = params => http.post('/service/erp/coupon/updateAgentLimit',params,false);
export const getSumOfCoupon = params => http.post('/service/erp/coupon/getSumOfCoupon',params,false);
export const getCouponList = params => http.post('/service/erp/coupon/getCouponList',params,false);
export const receiveCoupon = params => http.post('/service/erp/coupon/receiveCoupon',params,false);
export const getSendConpon = params => http.post('/service/erp/coupon/getSendConpon',params,false);
export const getSignByWeb = params => http.post('/service/erp/coupon/getSignByWeb',params,false);

export const addXpath = params => http.post('/service/erp/xpath/addXpath',params,false);
export const getXpath = params => http.post('/service/erp/xpath/getXpath',params,false);
export const deleteXpath = params => http.post('/service/erp/xpath/deleteXpath',params,false);
export const changeXpath = params => http.post('/service/erp/xpath/changeXpath',params,false);

export const getUserList = params => http.post('/service/erp/user/getUserList',params,false);
export const updateUserInfo = params => http.post('/service/erp/user/updateUserInfo',params,false);

export const getCashOutList = params => http.post('/service/getCashOutList',params,false);
export const changeCashOut = params => http.post('/service/changeCashOut',params,false);
export const transferAccounts = params => http.post('/service/transferAccounts',params,false);
export const refreshStatisData = params => http.post('/service/refreshStatisData',params,false);
export const setDebug = params => http.post('/service/erp/user/setDebug',params,false);
export const makeDiscountCode = params => http.post('/service/pay/makeDiscountCode',params,false);
export const getShopMinPrice = params => http.post('/service/pay/getShopMinPrice',params,false);
export const buyingVip = params => http.post('/service/pay/buyingVip',params,false);
export const buyPackage = params => http.post('/service/pay/buyPackage',params,false);
export const makeGroupBuying = params => http.post('/service/pay/makeGroupBuying',params,false);
export const buyTools = params => http.post('/service/pay/buyTools',params,false);
export const getFeedback = params => http.post('/service/getFeedback',params,false);
export const getGroupBuying = params => http.get('/service/pay/getGroupBuying',params,false);
export const getDiscountCode = params => http.get('/service/pay/getDiscountCode',params,false);
export const getPayList = params => http.post('/service/pay/getPayList',params,false);
export const getVersion = params => http.get('/service/erp/public/version',params,false);
export const setVersion = params => http.get('/service/erp/setversion',params,false);
export const getTokenByCode = params => http.post('/service/erp/wechat/getTokenByCode',params,false);
export const wechatJsSign = params => http.post('/service/erp/wechat/jsSign',params,false);
export const getAgentData = params => http.post('/service/getAgentData',params,false);


export const distinguishBasic = params => http.uploadPost('/service/textocr',params,false);
export const addCashOut = params => http.uploadPost('/service/addCashOut',params,false);


