import React from "react";
import { getLimitMoney, getAgentCouponList, splitCoupon, buyTools, } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Radio, Flex, Input, Col, Row, Modal, Table, Select, message, Popconfirm, Badge, Image, Popover, QRCode, Spin } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import WechatJsCommon from './components/WechatJsCommon';
import ReactWebsocket from './components/ReactWebsocket.js';
import ShareImage from './ShareImage.js';
import ShareBox from './components/ShareBox';
import ShareCouponBox from './components/ShareCouponBox';


//兑换代金券 页面

class AgentExchangePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      paramsParams: {},//分页查询的参数缓存
      couponMoney_limit: 0,//代金券可拆分额度
      agentCouponList_tabelData: [],
      agentCouponList_total: 0,
      numberOfAddLimit: 1000,
      showModel_Exchange: false,
      showModel_sendCoupon: false,
      showModal_addCouponLimit: false,
      showModal_payQrCode: false,
      websocket_pay: false,
      payQrCodeUrl: null,
      sendCouponData: null,
      isWechat: CommonUtils.isWeiXin(),
      couponType: null,//用户缓存查询的类型，渲染时方便处理
      spinning: false,//是否为加载中状态
      make_ShareImage: false,
      showModel_recommend: false,
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部
    //获取缓存的用户信息
    var user_cache = CommonUtils.getLocalStorage("user");//缓存用户信息
    this.setState({
      "user": user_cache,
    })

    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()//初始化方法
      }, 3000);
    } else {
      _this.initFun()//初始化方法
    }



  }

  //初始化方法
  initFun = () => {
    let _this = this;
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getLimitMoneyFun()//查询拆分额度
    this.getAgentCouponListFun() //查询代理人拆分的代金券
  }


  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //查询拆分额度
  getLimitMoneyFun = () => {
    let _this = this;
    getLimitMoney({}).then(res => {

      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          couponMoney_limit: resData.couponMoney
        })
      } else {
        _this.setState({
          couponMoney_limit: 0
        })
      }
    });
  }


  //分页查询代理人拆分的代金券
  agentCouponPageChangeFun = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getAgentCouponListFun(paramsParams) //查询代理人拆分的代金券
  }

  //查询代理人拆分的代金券
  getAgentCouponListFun = (params) => {
    // console.log("params===>",params)
    var user = CommonUtils.getLocalStorage("user")
    let _this = this;
    if (!params) {
      params = {}
    }

    //默认不用时间范围,加上时间范围,查询太慢了(1分钟多).
    // if (!params.rangeTime) {
    //   params["rangeTime"] = [dayjs().startOf('year').valueOf(), dayjs().endOf('day').valueOf()]
    // }

    //缓存参数，分页时候使用
    this.setState({
      "paramsParams": params,
      "couponType": params.couponType,
      spinning: true,
    })

    getAgentCouponList(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['url'] = window.location.origin + "/home?register=1&recommend=" + user.userId + "&couponCode=" + t.couponCode // 邀请链接
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        // console.log("resData >>>>>",resData)

        _this.setState({
          agentCouponList_total: resData.total,
          agentCouponList_tabelData: resData.records,
        })
        // message.info("查询成功！")
      } else {
        _this.setState({
          agentCouponList_total: 0,
          agentCouponList_tabelData: [],
        })
      }
    });
  }



  //拆分代金券
  splitCouponFun = () => {
    const { couponMoney_limit, couponTime, couponMoney, couponCount, couponType } = this.state;
    let _this = this;

    // console.log(couponCount,couponMoney,couponMoney * couponCount)
    // couponCount = parseInt(couponCount)

    if (!couponMoney || !couponCount || !couponTime) {
      message.info("请先选则对应的选项!")
      return
    }

    if (couponMoney_limit == 0) {
      message.info("可兑换金币为0，请先充值！或者等到下个月刷新金币数量！")
      return
    }

    if (couponMoney * couponCount > couponMoney_limit) {//  1000从数据库获取
      console.log("所需金币数量超过已有金币数量，请先充值！")
      message.info("所需金币数量超过已有金币数量，请先充值！")
      return
    }

    this.setState({
      showModel_Exchange: false,
    })

    splitCoupon({ "couponMoney": couponMoney, "couponCount": couponCount, "validTime": couponTime, couponType: couponType }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        message.info("拆分成功！")
        this.getLimitMoneyFun()//查询拆分额度
        this.getAgentCouponListFun() //查询代理人拆分的代金券

      } else {
        message.info("请求失败，请重试！")
      }
    });

  }


  //赠送代金券方法
  sendCouponFun = (data) => {
    const {user} = this.state
    // console.log("data == > ", data)
    this.setState({
      "showModel_sendCoupon": true,
      "sendCouponData": data,
      "showModel_sendCoupon_key":(new Date()).getTime()
    })

    var couponCode_url = 'https://baodanerp.cn'
    if (user && data) {
        var couponCode_url = 'https://baodanerp.cn/home?recommend=' + user.userId + "&couponCode=" + data.couponCode
    }

    WechatJsCommon.sendCouponToFriend(couponCode_url, data.couponMoney)  //送代金券时设置转发内容
    // Modal.success({
    //   title: '分享内容已准备好',
    //   content: '点击右上角的...可分享给朋友！',
    // })

  }

  //充值金币
  buyAddCouponLimit = () => {
    const { numberOfAddLimit } = this.state;
    let _this = this;
    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()
    var isWeiXin = CommonUtils.isWeiXin()
    var params = {
      "type": "couponLimit",
      "buyCount": numberOfAddLimit,
      "payablePrice": 0.05 * numberOfAddLimit,
      "h5": isPhone,//是否是H5唤起手机支付
      "wechat": isWeiXin,//是否是微信浏览器唤起手机支付
    }
    buyTools(params).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        console.log(resData)

        if (isWeiXin) {
          _this.setState({
            websocket_pay: true,
          })
          WechatJsCommon.jsApiPay(resData.appId, resData.timeStamp, resData.nonceStr, resData.package, resData.paySign)

        } else if (isPhone) {//唤起手机支付
          _this.setState({
            websocket_pay: true,
          })
          window.open(resData)
        } else {//扫码支付
          _this.setState({
            payQrCodeUrl: resData,
            showModal_payQrCode: true,
            websocket_pay: true,
          })
        }

      } else {
        _this.setState({
          payQrCodeUrl: null,
          showModal_payQrCode: false,
          websocket_pay: false,
        })
      }
    })
  }

  //websocket 事件
  onOpen = () => {
    console.log("onOpen")
  }

  //websocket 事件   SUCCESS == 支付成功
  onMessage = (msg) => {
    // console.log("onMessage", msg)
    try {
      msg = JSON.parse(msg)
      if (msg && msg.result && msg.result == "SUCCESS") {
        this.setState({
          payQrCodeUrl: null,//关闭支付二维码
          showModal: true,
          showModal_payQrCode: false,
          websocket_pay: false,
          showModal_addCouponLimit: false,
        })

        //缓存权限
        CommonUtils.setLocalStorage("roleData", msg.roleData)
        this.getLimitMoneyFun()//查询拆分额度
        message.info("充值成功!")
      }
    } catch (e) { }
  }

  //websocket 事件
  onClose = () => {
    console.log("onClose")
  }






  render() {
    const { couponMoney_limit, agentCouponList_tabelData, agentCouponList_total, showModel_Exchange, showModel_sendCoupon, couponType, sendCouponData,
      showModal_addCouponLimit, numberOfAddLimit, websocket_pay, showModal_payQrCode, payQrCodeUrl, isWechat, couponMoney, couponCount, spinning, user,
      make_ShareImage, showModel_recommend,showModel_sendCoupon_key
    } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()
    var buttonSize = isPhone ? "middle" : "large"  //large  middle   small 

    const searchItems = [
      {
        "title": "状态",
        "key": "couponType",
        "type": "select",
        "defaultValue": false,
        // "tooltipText": "1",
        "options": [
          { label: "全部", value: "全部" },
          { label: "已领取部分", value: "已领取部分" },
          { label: "已领取全部", value: "已领取全部" },
          { label: "已使用部分", value: "已使用部分" },
          { label: "已使用全部", value: "已使用全部" },
          { label: "未领取", value: "未领取" },
          { label: "已过期", value: "已过期" },
        ]
      },
      {
        "title": "创建时间",
        "key": "rangeTime",
        "type": "rangeTime",
        "placeholder": "时间",
      },

    ]


    var columns_Coupon_split = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
        // render: (text) => <a>{text}</a>,
      },
      {
        title: '代理人ID',
        dataIndex: 'agentUserId',
        key: 'agentUserId',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '验证码',
        dataIndex: 'couponCode',
        key: 'couponCode',
      },
      // {
      //   title: '类型',
      //   dataIndex: 'couponType',
      //   key: 'couponType',
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      // },

      {
        title: '金额',
        dataIndex: 'couponMoney',
        key: 'couponMoney',
        render: (text) => <span>{text}元</span>,
        // responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '数量',
        dataIndex: 'couponCount',
        key: 'couponCount',
        render: (text) => <span>{text}张</span>,
        // responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '已领取',
        dataIndex: 'receiveCount',
        key: 'receiveCount',
        // render: (text) => <span>{text}张</span>,
        render: (text, record, index) => {
          if (record.receiveCount && record.couponCount && record.receiveCount > record.couponCount) {
            return <span>{record.couponCount}张</span>
          } else {
            return <span>{record.receiveCount}张</span>
          }
        },
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '已使用',
        dataIndex: 'usedCount',
        key: 'usedCount',
        // render: (text) => <span>{text}张</span>,
        render: (text, record, index) => {
          if (record.usedCount && record.couponCount && record.usedCount > record.couponCount) {
            return <span>{record.couponCount}张</span>
          } else {
            return <span>{record.usedCount}张</span>
          }
        },
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '状态',
        dataIndex: 'couponstatus',
        key: 'couponstatus',
        render: (text, record, index) => {
          //根据查询条件，写死
          if (couponType && couponType != "全部") {
            if (couponType == "已领取部分") {
              return <span className="couponStatusblue">已领取部分</span>
            }
            if (couponType == "已领取全部") {
              return <span className="couponStatusblue">已领取全部</span>
            }
            if (couponType == "已使用部分") {
              return <span className="couponStatusgreen">已使用部分</span>
            }
            if (couponType == "已使用全部") {
              return <span className="couponStatusgreen">已使用全部</span>
            }
            if (couponType == "未领取") {
              return <span className="couponStatusRed">未领取</span>
            }
            if (couponType == "已过期") {
              return <span className="couponStatusgrey">已过期</span>
            }
          }

          var couponCount = record.couponCount
          var receiveCount = record.receiveCount
          var usedCount = record.usedCount
          var endTime = record.endTime
          if (!receiveCount) {
            return <span className="couponStatusRed">未领取</span>
          }
          if (!usedCount && receiveCount >= couponCount) {
            return <span className="couponStatusblue">已领取全部</span>
          }
          if (!usedCount) {
            return <span className="couponStatusblue">已领取部分</span>
          }

          if (couponCount <= usedCount) {
            return <span className="couponStatusgreen">已使用全部</span>
          }

          if (endTime < parseInt((new Date()).getTime() / 1000) && couponCount > usedCount) {
            return <span className="couponStatusgrey">已过期</span>
          }

          if (couponCount > usedCount) {
            return <span className="couponStatusgreen">已使用部分</span>
          }



        },
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      // {
      //   title: '是否使用',
      //   dataIndex: 'statusType',
      //   key: 'statusType',
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      //   render: (text) => {
      //     if (text == 2) {
      //       return <Badge count={"已使用"} style={{ backgroundColor: '#52c41a', }} />
      //     } else {
      //       return <Badge count={"未使用"} style={{ backgroundColor: '#d3d3d3', }} />
      //     }

      //   },
      // },
      // {
      //   title: '代金券网址',
      //   dataIndex: 'url',
      //   key: 'url',
      //   ellipsis: true,
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      //   render: (text, record, index) => {
      //     return <Typography.Paragraph ellipsis copyable={{ text: text }} style={{ margin: "0px" }}> {text} </Typography.Paragraph>
      //   },
      // },
      // {
      //   title: '二维码',
      //   dataIndex: 'qrcode',
      //   key: 'qrcode',
      //   responsive: ['lg'],//响应式配置。未设置则始终可见
      //   render: (text, record, index) => {
      //     return <Popover overlayInnerStyle={{ padding: 0, }} content={<QRCode value={record.url} bordered={false} />} >
      //       <QrcodeOutlined />
      //     </Popover>
      //   },
      // },
      // {
      //   title: '生成图片',
      //   dataIndex: 'makeImage',
      //   key: 'makeImage',
      //   render: (text, record, index) => {
      //     return <Button size="small" onClick={() => this.makeImageFun(record)}>生成图片</Button>
      //   },
      // },
      {
        title: '有效时间',
        dataIndex: 'validTime',
        key: 'validTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
      },
      {
        title: '创建时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
      {
        title: '过期时间',
        dataIndex: 'endTimeFormat',
        key: 'endTimeFormat',
        responsive: ['lg'],//响应式配置。未设置则始终可见
        ellipsis: true,
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record) => {
          return <Button size="small" className="exchangeBtnSmall" onClick={() => this.sendCouponFun(record)}>赠送代金券</Button>
        },
      },
    ];


    // console.log("sendCouponData ==>",sendCouponData)

    var couponCode_url = 'https://baodanerp.cn'
    if (user && sendCouponData) {
      var couponCode_url = 'https://baodanerp.cn/home?recommend=' + user.userId + "&couponCode=" + sendCouponData.couponCode
    }

    var recommendUrl = 'https://baodanerp.cn'
    if (user) {
      recommendUrl = 'https://baodanerp.cn/home?recommend=' + user.userId
    }


    return (
      <React.Fragment>
        {
          websocket_pay ?
            <ReactWebsocket
              url={CommonUtils.baseWs + "/websocket/" + CommonUtils.getLocalStorage("token") + "/" + (new Date()).getTime() + "/0"}
              onMessage={this.onMessage} //支付   ---  接受信息的回调
              onOpen={this.onOpen} //websocket打开 
              onClose={this.onClose} //websocket关闭
              reconnect={true}
              debug={true}
              ref={Websocket => {
                this.refWebSocket = Websocket;
              }}
            />
            : null
        }

        <div className="exchangeCouponHeader" style={isPhone ? { lineHeight: "50px", padding: "15px" } : { lineHeight: "80px" }}>
          <Row justify={"left"} align={"middle"}>
            <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={4} style={isPhone ? { textAlign: "left" } : { textAlign: "center" }} >
              <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModel_Exchange", true)}>兑换代金券</Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={6} xxl={6}  >
              <Popconfirm
                title="说明"
                description={"我的金币每个月1号刷新一次，低于1000个的，将重新赋予1000个金币。高于1000个的不变。"}
                showCancel={false}
                icon={<QuestionCircleOutlined style={{ color: "#575757" }} />}
                style={{ maxWidth: "200px" }}
              >
                <div>
                  <div className="exchangeCouponMy">我的金币：{couponMoney_limit}个</div>
                  <div className="exchangeCouponDetailText">1000金币可兑换1000元代金券</div>
                </div>
              </Popconfirm>


            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}  >
              <div>
                <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModal_addCouponLimit", true)}>充值</Button>
                <span className="exchangeCouponBuyText">50元人民币充值1000金币</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={7}  >
              <div style={{ lineHeight: "16px" }}>
                <Button className="exchangeBtn" onClick={() => this.setStateKeyValue("showModel_recommend", true)}>邀请好友</Button>
                <span className="exchangeCouponBuyText">邀请一个新客户注册获赠100金币</span>
              </div>
            </Col>

          </Row>
        </div>

        <SearchItem searchFun={this.getAgentCouponListFun} searchItems={searchItems} searchText="查询" />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns_Coupon_split} dataSource={agentCouponList_tabelData} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
              total: agentCouponList_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.agentCouponPageChangeFun
            }} />
        </Spin>


        <Modal title="兑换代金券" open={showModel_Exchange} footer={null} onCancel={() => this.setStateKeyValue("showModel_Exchange", false)}>
          <div>

            <div className="">
              <div className="exchangePagemodelDetail">现有可兑换金币 {couponMoney_limit}个
                {
                  couponMoney_limit == 0 || (couponCount && couponMoney && couponCount * couponMoney > couponMoney_limit) ?
                    <Button className="exchangeBtn" style={{ marginLeft: "155px" }} onClick={() => {
                      this.setStateKeyValue("showModel_Exchange", false);
                      this.setStateKeyValue("showModal_addCouponLimit", true)
                    }}>充值</Button>
                    : null
                }

              </div>
              <div>
                <span className="modelTitle">代金券金额</span>
                <Select className="modelSelect"
                  onChange={(value) => this.setStateKeyValue("couponMoney", value)}
                  options={[
                    { label: '1元', value: 1, },
                    { label: '5元', value: 5, },
                    { label: '10元', value: 10, },
                    { label: '20元', value: 20, },
                    { label: '50元', value: 50, },
                    { label: '100元', value: 100, },
                    { label: '500元', value: 500, },
                    { label: '1000元', value: 1000, },
                    // { label: '5000元', value: 5000, },
                    // { label: '10000元', value: 10000, },
                  ]}
                />
              </div>

              <div>
                <span className="modelTitle">代金券数量</span>
                <Input className="modelSelect" placeholder="请输入兑换数量" type="number" onChange={(e) => this.setStateKeyValue("couponCount", e.target.value)} />
              </div>

              <div>
                <span className="modelTitle">设置有效期</span>
                <Select className="modelSelect"
                  onChange={(value) => this.setStateKeyValue("couponTime", value)}
                  options={[
                    { label: '1天', value: "1天", },
                    { label: '3天', value: "3天", },
                    { label: '1周', value: "1周", },
                    { label: '1个月', value: "1个月", },
                    { label: '1季度', value: "1季度", },
                    { label: '半年', value: '半年', },
                    { label: '1年', value: '1年', },
                  ]}
                />
              </div>

              <div>
                <Button className="modelBtn modelBtnSure" style={isPhone ? {} : { marginLeft: "88px" }} onClick={this.splitCouponFun}>确定</Button>
                <Button className="modelBtn" onClick={() => this.setStateKeyValue("showModel_Exchange", false)}>取消</Button>
              </div>

            </div>
          </div>

        </Modal>


        <Modal title="赠送代金券" open={showModel_sendCoupon} footer={null} onCancel={() => this.setStateKeyValue("showModel_sendCoupon", false)} >
          <ShareCouponBox sendCouponData={sendCouponData} key={showModel_sendCoupon_key}></ShareCouponBox>
        </Modal>

        <Modal title="邀请好友" open={showModel_recommend} footer={null} onCancel={() => this.setStateKeyValue("showModel_recommend", false)}>
          <div className="recommendBox">
            <ShareBox url={recommendUrl}></ShareBox>
          </div>
        </Modal>

        <Modal
          title={"充值金币"}
          open={showModal_addCouponLimit}
          // open={true}
          onCancel={() => this.setStateKeyValue("showModal_addCouponLimit", false)}
          onOk={this.buyAddCouponLimit}
          width={600}
          cancelText="取消"
          okText="立即订购"
          centered
        >
          <div className="messageBox">
            <span className="messageTitle">说明:</span>
            <span className="messageText">充值金币可以用来兑换代金券.</span>
          </div>
          <div className="messageBox">
            <span className="messageTitle">订购套餐:</span>
            <div>
              <Radio.Group value={numberOfAddLimit} onChange={(e) => this.setStateKeyValue("numberOfAddLimit", e.target.value)} size={buttonSize}>
                <Radio.Button value={1000}>一千金币</Radio.Button>
                <Radio.Button value={3000}>三千金币</Radio.Button>
                <Radio.Button value={10000}>一万金币</Radio.Button>
                <Radio.Button value={30000}>三万金币</Radio.Button>
                <Radio.Button value={50000}>五万金币</Radio.Button>
                <Radio.Button value={100000}>十万金币</Radio.Button>
                <Radio.Button value={150000}>十五万金币</Radio.Button>
                <Radio.Button value={200000}>二十万金币</Radio.Button>
                <Radio.Button value={500000}>五十万金币</Radio.Button>
                <Radio.Button value={1000000}>一百万金币</Radio.Button>
                <Radio.Button value={2000000}>两百万金币</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="messageBox">
            <span className="messageTitle">应付金额:</span>
            <span className="textPrice">￥{parseInt(0.05 * numberOfAddLimit)} <span className="textOriginalPrice" style={{ position: 'absolute' }}>￥{parseInt(0.1 * numberOfAddLimit)}</span></span>
          </div>

        </Modal>

        <Modal
          title={"扫码支付"}
          open={showModal_payQrCode}
          onCancel={() => this.setStateKeyValue("showModal_payQrCode", false)}
          onOk={() => this.setStateKeyValue("showModal_payQrCode", false)}
          cancelText="取消"
          okText="支付完毕"
          centered
        >
          {
            payQrCodeUrl ?
              <div style={{ textAlign: "center" }}>
                <div style={{ textAlign: "center", display: "inline-block" }}>
                  <QRCode value={payQrCodeUrl || '-'} errorLevel={"H"} icon={"/img/wechat_logo.png"} />
                  微信扫码支付
                </div>
                {
                  isPhone && isWechat ?
                    <div style={{ margin: "20px 0px" }}>
                      提示：用手机浏览器发起支付，可自动唤起微信支付。
                    </div>
                    : null
                }
                <div style={{ margin: "20px 0px" }}>
                  <Checkbox checked></Checkbox>
                  <span> 我已阅读并同意</span> <span className="register_xieyi">
                    <NavLink to="/agreement" target="_blank">
                      <span  >《爆单ERP系列会员服务协议》</span>
                    </NavLink>
                  </span>
                </div>
              </div>
              : <span>获取支付码失败，请重试！</span>
          }

        </Modal>
      </React.Fragment>
    );
  }
}

export default AgentExchangePage;