import React from "react";
import { getAgentLimit, updateAgentLimit,  } from "../http/api.js";
import CommonUtils from '../http/Common.js';
import { Modal, Input, Button, Select, Space, Form, Tooltip, Image, Table, message, Spin } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import dayjs from 'dayjs';

//代金券列表界面

class CouponManagerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableData_total: 0,
            payIdArray: [],
            params: {},
            spinning: false,//是否为加载中状态
            showModel_updateAgentLimit: false,
            showModelData: null,
        };
    }

    componentDidMount() {
        let _this = this;
        CommonUtils.goToTop()//回到顶部

        this.getAgentLimitFun({});//获取审核列表
    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //查询_分页
    getAgentLimitFun_Page = (current, pageSize) => {
        var paramsParams = this.state.paramsParams
        paramsParams["current"] = current
        paramsParams["size"] = pageSize
        this.getAgentLimitFun(paramsParams) //查询代理人拆分的代金券
    }

    //获取列表
    getAgentLimitFun = (params) => {
        let _this = this;
        //console.log("params==>", params)

        if (params.otherKey) {
            params["erpUserId"] = params.otherKey
        }

        this.setState({
            paramsParams: params,
            spinning: true,
        }) //缓存参数，用于修改之后刷新

        // params["createdTime"] = params.startTime
        // params["lastUpdateTime"] = params.endTime
        getAgentLimit(params).then(res => {
            _this.setState({ spinning: false })
            if (res && res.data && res.data.success) {
                let resData = res.data.data;

                resData.records.map((t, i) => {
                    t['index'] = (resData.current - 1) * resData.size + i + 1
                })
                _this.setState({
                    tableData: resData.records,
                    tableData_total: resData.total,
                })
            } else {
                _this.setState({
                    tableData: [],
                    tableData_total: 0,
                })
            }
        }).catch(function (error) {//请求失败时
            console.log(error)
            message.info("操作失败，请重试！")
        })
    }


    updateAgentLimitFun = (params) =>{
        let _this = this;
        console.log(params)
        
        updateAgentLimit(params).then(res=>{
            if (res && res.data && res.data.success) {
                let resData = res.data.data;
                _this.setState({showModel_updateAgentLimit:false})
                message.info("操作成功！")
               _this.getAgentLimitFun_Page(0,10)
            } else {
                message.info("操作失败，请重试！")
            }
        })
    }


    render() {
        const { tableData, tableData_total, spinning, showModel_updateAgentLimit, showModelData } = this.state;
        //判断是不是手机屏幕
        var isPhone = CommonUtils.isPhoneClient()

        const searchItems = [
            {
                "title": "注册时间",
                "key": "rangeTime",
                "type": "rangeTime",
            },

            {
                "title": "用户ID",
                "key": "otherKey",
                "type": "input",
                "placeholder": "用户ID",
            },
        ]

        var columns_table = [
            {
                title: '序号',
                dataIndex: 'index',
                key: 'index',
                width: 45,
            },
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '用户ID',
                dataIndex: 'erpUserId',
                key: 'erpUserId',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '金币数量',
                dataIndex: 'couponMoney',
                key: 'couponMoney',
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                responsive: ['lg'],//响应式配置。未设置则始终可见
                render: (status) => {
                    if (status) {
                        return <span>正常</span>
                    } else {
                        return <span>停用</span>
                    }
                },
            },
            {
                title: '创建时间',
                dataIndex: 'createdTime',
                key: 'createdTime',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '备注',
                dataIndex: 'remarks',
                key: 'remarks',
                ellipsis: true,
                responsive: ['lg'],//响应式配置。未设置则始终可见
            },
            {
                title: '操作',
                key: 'action',
                width: "48px",//只有文字才有用
                render: (_, record) => (
                    <Space size="middle">
                        <span className="colCardActionBtn" onClick={() => {
                            this.setState({
                                showModel_updateAgentLimit: true,
                                showModelData: record
                            })
                        }}>修改</span>
                    </Space>
                ),
            },
        ];




        return (
            <React.Fragment>
                <SearchItem searchFun={this.getAgentLimitFun} searchItems={searchItems} />
                <Spin tip="查询中..." spinning={spinning}>
                    <Table columns={columns_table} dataSource={tableData} size={isPhone ? "small" : "middle"}
                        pagination={{
                            position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100, 300, 500, 1000, 10000, 100000],
                            defaultPageSize: 10,
                            total: tableData_total,
                            showTotal: (total) => `共 ${total} 条`,
                            onChange: this.getAgentLimitFun_Page
                        }}
                    />
                </Spin>

                <Modal title="修改我的金币" open={showModel_updateAgentLimit} footer={null} onCancel={() => this.setStateKeyValue("showModel_updateAgentLimit", false)}>
                    {
                        showModelData ?
                            <Form labelCol={{ span: 4, }}
                                wrapperCol={{ span: 20, }}
                                style={{ maxWidth: 1200, }}
                                autoComplete="off"
                                key={showModelData.erpUserId}
                                onFinish={this.updateAgentLimitFun}
                            >
                                <Form.Item label="用户ID" name="erpUserId" initialValue={showModelData ? showModelData.erpUserId : null} >
                                    <span>{showModelData ? showModelData.erpUserId : null}</span>
                                </Form.Item>
                                <Form.Item label="我的金币" name="couponMoney" initialValue={showModelData ? showModelData.couponMoney : null}>
                                    <Input defaultValue={showModelData ? showModelData.couponMoney : null} />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 5, span: 16, }} >
                                    <Button type="primary" htmlType="submit">修改</Button>
                                </Form.Item>
                            </Form>
                            : null
                    }

                </Modal>

            </React.Fragment>
        );
    }
}

export default CouponManagerPage;