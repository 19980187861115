import React from "react";
import { Button, QRCode } from "antd"

class ShareCard extends React.Component {
    constructor(props) {
        super(props);
        this.image = new Image();
        this.image.src = props.backgroundImg; // 447 *795
        this.state = {
            qrCodeValue: props.qrCodeValue,//二维码内容 url
            writeText: props.writeText,//写入文字
        }
    }

    componentDidMount() {
        let _this = this;
        setTimeout(() => {
            _this.makeImgae() // 延后一点生成才可以
        }, 500);
    }

    //下载图片
    downloadQRCode = () => {
        const { writeText, qrCodeValue } = this.state;
        // 根据第一级 id 获取元素
        var firstLevelElement = document.getElementById(qrCodeValue);
        var canvas = firstLevelElement.querySelector('#advertisingCanvas')
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = '爆单ERP优惠券' + writeText + '.png';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    //制作图片
    makeImgae = () => {
        const { writeText, qrCodeValue } = this.state;

        // 二维码元素
        // var canvas_qrcode = document.getElementById('myqrcode')?.querySelector('canvas');
        var canvas_qrcode = null;

        // 图片canvas
        // var advertisingCanvas = document.getElementById('advertisingCanvas')
        var advertisingCanvas = null

        // 根据第一级 id 获取元素
        var firstLevelElement = document.getElementById(qrCodeValue);
        if (firstLevelElement) {
            // 根据第二级 id 获取元素
            // var secondLevelElement = firstLevelElement.querySelector('#second-level');

            canvas_qrcode = firstLevelElement.querySelector('#myqrcode')?.querySelector('canvas');
            advertisingCanvas = firstLevelElement.querySelector('#advertisingCanvas')
        } else {
            console.log("未找到第一级 id");
            return
        }


        if (!canvas_qrcode || !advertisingCanvas) { return }

        // 获取合并后的Canvas的上下文对象
        var mergedContext = advertisingCanvas.getContext('2d');
        var image_width = 270
        var image_height = 550
        mergedContext.drawImage(this.image, 0, 0, image_width, image_height); // 绘制图像到画布上，x、y分别为左上角位置的坐标  this.image.width  this.image.height

        // var qr_start_x = (image_width - qr_width) / 2
        // var qr_start_y = image_height - qr_height - 10
        var qr_start_x = 125
        var qr_start_y = image_height - 60

        // console.log("qr_start_x,qr_start_y ===>", qr_start_x, qr_start_y)
        // 将二维码绘制到Canvas上
        // mergedContext.drawImage(canvas_qrcode, qr_start_x, qr_start_y);
        mergedContext.drawImage(canvas_qrcode, qr_start_x, qr_start_y, 50, 50);


        if (writeText) {
            // 设置字体样式、大小等属性
            mergedContext.font = "20px Arial";
            mergedContext.fillStyle = "#FF0000"; // 红色
            // 将文本写入画布的指定位置
            mergedContext.fillText(writeText, qr_start_x, qr_start_y - 20);
        }

    };

    render() {
        const { qrCodeValue } = this.state;
        return (
            <React.Fragment>
                <div id={qrCodeValue} style={{ textAlign: "center", width: "270px", margin: "5px" }} >
                    <div style={{ position: "relative", height: "650px"}}>
                        <div id="myqrcode" style={{ position: "absolute", top: "0px", left: "0px" }}>
                            <QRCode
                                value={qrCodeValue}
                                bgColor="#fff"
                                errorLevel="H"
                                // size={100}
                                style={{ opacity: 0, width: "50px" }}
                            />
                            <canvas id="advertisingCanvas" width="270" height="550" style={{ position: "absolute", top: "0px", left: "0px" }} ></canvas>
                        </div>
                    </div>
                    <Button type="primary" onClick={this.downloadQRCode} style={{ margin: "5px" }}>下载图片</Button>
                </div>

            </React.Fragment>
        );
    }
}

export default ShareCard;





