import React from "react";
import { getRecommendList } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Checkbox, Form, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Spin } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import SearchItem from "./SearchItem.js";
import WechatJsCommon from './components/WechatJsCommon';
import dayjs from 'dayjs';



//邀请记录

class AgentInvitePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recommendList: [],
      recommend_total: 0,
      spinning: false,//是否为加载中状态
    };
  }

  componentDidMount() {
    let _this = this;
    // CommonUtils.goToTop()//回到顶部



    var isWeiXinLogin = CommonUtils.isWeiXinLogin() //判断是不是微信登录
    if (isWeiXinLogin) {
      setTimeout(() => {
        _this.initFun()
      }, 3000);
    } else {
      _this.initFun()
    }

  }

  //初始化方法
  initFun = () => {
    let _this = this;
    WechatJsCommon.wechatJsSignFun()//给微信的浏览器中签名，以便转发的时候使用到 
    this.getRecommendListFun()//查询邀请记录
  }


  setStateKeyValue = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  //查询转化记录_分页
  getRecommendListFun_Page = (current, pageSize) => {
    var paramsParams = this.state.paramsParams
    paramsParams["current"] = current
    paramsParams["size"] = pageSize
    this.getRecommendListFun(paramsParams) //查询代理人拆分的代金券
  }

  //查询邀请记录
  getRecommendListFun = (params) => {
    //默认不用时间范围,加上时间范围,查询太慢了(1分钟多).
    let _this = this;
    if (!params) {
      params = {}
    }

    this.setState({
      paramsParams: params,
      spinning: true,
    })


    getRecommendList(params).then(res => {
      _this.setState({ spinning: false })
      if (res && res.data && res.data.success) {
        let resData = res.data.data;

        resData.records.map((t, i) => {
          t['index'] = (resData.current - 1) * resData.size + i + 1
        })

        _this.setState({
          recommendList: resData.records,
          recommend_total: resData.total,
        })

      } else {
        _this.setState({
          recommendList: [],
          recommend_total: 0,
        })
        message.info("请求失败，请重试！")
      }
    });

  }


  render() {
    const { recommendList, recommend_total, spinning } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()

    const searchItems_invite = [
      {
        "title": "注册时间",
        "key": "rangeTime",
        "type": "rangeTime",
      },
    ]

    var columns_recommend = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 45,
      },
      {
        title: '邀请用户',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: '注册时间',
        dataIndex: 'createdTime',
        key: 'createdTime',
        ellipsis: true,
      },
    ];

    return (
      <React.Fragment>
        <SearchItem searchFun={this.getRecommendListFun} searchItems={searchItems_invite} />
        <Spin tip="查询中..." spinning={spinning}>
          <Table columns={columns_recommend} dataSource={recommendList} size={isPhone ? "small" : "middle"}
            pagination={{
              position: ["bottomCenter"], showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100],
              total: recommend_total, showTotal: (total) => `共 ${total} 条`,
              onChange: this.getRecommendListFun_Page
            }} />
        </Spin>
      </React.Fragment>
    );
  }
}

export default AgentInvitePage;