import React from "react";
import { Descriptions, Divider, Button, Checkbox, QRCode, Flex, Input, Col, Row, Modal, Table, Space, message, Popconfirm, Badge, Typography, Popover, Tooltip, Image, Spin, Tag, } from 'antd';
import { QuestionCircleOutlined, QrcodeOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import ShareImage from '../ShareImage.js';
import CommonUtils from '../../http/Common';
import WechatJsCommon from './WechatJsCommon';

class ShareBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsParams: {},//分页查询的参数缓存
            spinning: false,//是否为加载中状态
            make_ShareImage: false,
            url: props.url,
            couponMoney: props.couponMoney ? props.couponMoney : 100,
        };
    }

    componentDidMount() {
        let _this = this;
        // CommonUtils.goToTop()//回到顶部

    }

    setStateKeyValue = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    //跳转到另一个路由
    jumpToNewHash = (hashPath) => {
        this.props.history.push(hashPath);//跳转到新的路由
    }

    //分享到微信  (从微信打开才有效果)
    sendFun_wechat = () => {
        let _this = this;
        const {url,couponMoney} = this.state
        console.log("分享到微信==>")
        if (CommonUtils.isWeiXin()) {
            WechatJsCommon.sendCouponToFriend(url, couponMoney)  //送代金券时设置转发内容

            Modal.success({
                title: '分享内容已准备好',
                content: '点击右上角的...可分享给朋友！',
            })
        } else {
            this.setState({
                make_ShareImage: true
            })

            setTimeout(() => {
                this.setState({
                    make_ShareImage: false
                })
                Modal.success({
                    title: '图片已下载',
                    content: '将图片转发给朋友,扫码进入即可邀请成功！',
                })
            }, 2000);


        }
    }

    share_download = () =>{
        this.setState({
            make_ShareImage: true
        })

        setTimeout(() => {
            this.setState({
                make_ShareImage: false
            })
            Modal.success({
                title: '图片已下载',
                content: '将图片转发给朋友,扫码进入即可邀请成功！',
            })
        }, 2000);
    }

    render() {
        const { make_ShareImage, url, couponMoney } = this.state;
        //判断是不是手机屏幕
        // var isPhone = CommonUtils.isPhoneClient()

        return (
            <React.Fragment>
                {make_ShareImage ?
                    <ShareImage
                        // backgroundImg={require("../../img/advertising_100.png")}
                        backgroundImg={require("../../img/advertising_" + couponMoney + ".png")}
                        qrCodeValue={url}
                        writeText={couponMoney + "元"}
                        key={url}
                    />
                    : null}

                {/* 下面这个图片只为了缓存图片，避免使用的时候再加载出现延迟现象 */}
                <Image className="footerImg" preview={false} width={1} height={1} src={require("../../img/advertising_" + couponMoney + ".png")} style={{ opacity: 0 }}></Image>
                <Row justify={"center"} align={"middle"}>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="textAlignCenter cursorPointer" onClick={this.sendFun_wechat}>
                        <div><Image src="/img/pro/share_wechat.png"  width={56} preview={false}></Image></div>
                        <div className="shareTitleText">微信</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="textAlignCenter cursorPointer" onClick={this.sendFun_wechat}>
                        <div><Image src="/img/pro/share_friend.png" width={56} preview={false}></Image></div>
                        <div className="shareTitleText">微信朋友圈</div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="textAlignCenter cursorPointer" onClick={this.share_download}>
                        <div><Image src="/img/pro/share_download.png" width={56} preview={false}></Image></div>
                        <div className="shareTitleText">下载图片</div>
                    </Col>
                    {/* <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="textAlignCenter cursorPointer">
                        <Popover content={<QRCode value={url} bordered={false} errorLevel={"H"} placement="top" />}>
                            <>
                                <div><Image src="/img/pro/share_qrcode.png"  width={56} preview={false}></Image></div>
                                <div className="shareTitleText">分享二维码</div>
                            </>
                        </Popover>
                    </Col> */}
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="textAlignCenter cursorPointer" onClick={() => CommonUtils.copyData(url)}>
                        <div><Image src="/img/pro/share_copyUrl.png"  width={56} preview={false}></Image></div>
                        <div className="shareTitleText">复制链接</div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(ShareBox);