import React from "react";
import { getCouponList, receiveCoupon, } from "../http/api";
import CommonUtils from '../http/Common';
import { Descriptions, Divider, Button, Typography, Result, Input, message, Tabs, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import ShareCard from './ShareCard.js';


class CouponPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImg: require("../img/advertising.jpg"),
      writeText: null,
      qrCodeValue: "",//二维码的url
      coupon_noUsed: [],//未使用
      coupon_used: [],//已经使用
      coupon_expire: [],//过期
      couponCode: "",//验证码
      receiveCouponData: {},


    };
  }

  componentDidMount() {
    let _this = this;
    CommonUtils.goToTop()//回到顶部

    this.getCouponListFun("coupon_noUsed", 1)//查询代金券方法
    this.getCouponListFun("coupon_used", 2)//查询代金券方法
    this.getCouponListFun("coupon_expire", 3)//查询代金券方法


  }

  setStateKeyValue = (key, value) => {
    // console.log(key, value)
    this.setState({
      [key]: value
    })
  }

  //查询代金券方法
  getCouponListFun = (key, statusType) => {
    let _this = this;
    if (!key || !statusType) {
      return
    }

    getCouponList({ "statusType": statusType }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        _this.setState({
          [key]: resData
        })

      } else {
        _this.setState({
          [key]: [],
        })
        message.info("请求失败，请重试！")
      }
    });
  }

  sendToFriend = (writeText, couponCode) => {
    this.setState({
      writeText: writeText,
      showModel_shareCard: true,
      qrCodeValue: window.location.origin + "/home?login=true&couponCode=" + couponCode,// 邀请链接
      couponCode: couponCode,
    })
  }

  //领取代金券
  receiveCouponFun = () => {
    const { inputCouponCode } = this.state;
    let _this = this;

    receiveCoupon({ "couponCode": inputCouponCode }).then(res => {
      if (res && res.data && res.data.success) {
        let resData = res.data.data;
        // console.log("resData===>", resData)
        _this.setState({
          showModel_receiveSuccess: true,
          receiveCouponData: resData,
        })
        this.getCouponListFun("coupon_noUsed", 1)//查询代金券方法
      } else {
        message.info("领取失败，" + res.data.message)
      }
    });
  }



  render() {
    const { showModel_shareCard, writeText, backgroundImg, coupon_noUsed, coupon_used, coupon_expire, qrCodeValue, couponCode
      , showModel_receiveSuccess, receiveCouponData, } = this.state;

    //判断是不是手机屏幕
    var isPhone = CommonUtils.isPhoneClient()


    return (
      <React.Fragment>
        <Tabs
          type="card"
          size={isPhone ? "small" : "middle"} //large  middle   small  
          style={{ minHeight: "300px" }}
          items={[
            {
              label: "未使用",
              key: 1,
              children: <div style={{ textAlign: "center" }}>
                {coupon_noUsed.length > 0 ?
                  coupon_noUsed.map((t, i) => {
                    return (<div className="couponCard">
                      <div className="couponCardLeft">
                        代金券
                      </div>
                      <div className="couponCardContent">
                        <div className="couponCardTitle">
                          <span style={{ fontSize: "16px" }}>￥</span>
                          {t.couponMoney}
                          <span style={{ fontSize: "16px" }}>元</span>
                        </div>
                        <div style={{ color: "#FFF", fontSize: "12px" }}>{t.endTimeFormat.substring(0, 10)}过期</div>
                        {/* <div style={{ margin: "10px 0px" }}>
                          <Button size="small" style={{ padding: "0px 20px" }} onClick={() => this.sendToFriend(t.couponCode, t.couponCode)}>赠送</Button>
                        </div> */}
                      </div>
                    </div>);
                  })
                  : "无"


                }
              </div>,
            },
            {
              label: "已使用",
              key: 2,
              children: <div style={{ textAlign: "center" }}>
                {
                  coupon_used.length > 0 ?
                    coupon_used.map((t, i) => {
                      return (<div className="couponCard">
                        <div className="couponCardLeft">
                          代金券
                        </div>
                        <div className="couponCardContent">
                          <div className="couponCardTitle">
                            <span style={{ fontSize: "16px" }}>￥</span>
                            {t.couponMoney}
                            <span style={{ fontSize: "16px" }}>元</span>
                          </div>
                          <div style={{ color: "#FFF", fontSize: "12px" }}>{t.endTimeFormat.substring(0, 10)}过期</div>
                          {/* <div style={{ margin: "10px 0px" }}>
                            <Button size="small" style={{ padding: "0px 20px" }} onClick={() => this.sendToFriend(t.couponCode, t.couponCode)}>赠送</Button>
                          </div> */}
                        </div>
                      </div>);
                    })
                    : "无"
                }
              </div>,
            }
            ,
            {
              label: "已过期",
              key: 3,
              children: <div style={{ textAlign: "center" }}>
                {
                  coupon_expire.length > 0 ?
                    coupon_expire.map((t, i) => {
                      return (<div className="couponCard">
                        <div className="couponCardLeft">
                          代金券
                        </div>
                        <div className="couponCardContent">
                          <div className="couponCardTitle">
                            <span style={{ fontSize: "16px" }}>￥</span>
                            {t.couponMoney}
                            <span style={{ fontSize: "16px" }}>元</span>
                          </div>
                          <div style={{ color: "#FFF", fontSize: "12px" }}>{t.endTimeFormat.substring(0, 10)}过期</div>
                          {/* <div style={{ margin: "10px 0px" }}>
                            <Button size="small" style={{ padding: "0px 20px" }} onClick={() => this.sendToFriend(t.couponCode, t.couponCode)}>赠送</Button>
                          </div> */}
                        </div>
                      </div>);
                    })
                    : "无"
                }
              </div>,
            }
            ,
            {
              label: "领取代金券",
              key: 4,
              children: <>
                <div className="getConponTab">
                  <span style={{ lineHeight: "31px" }}>验证码:</span>
                  <Input style={{ width: "200px", maxWidth: "38vw", margin: "0px 10px", height: "31px" }} onChange={e => this.setStateKeyValue("inputCouponCode", e.target.value.trim())}></Input>
                  <Button type="primary" onClick={this.receiveCouponFun}>领取</Button>
                </div>
              </>,
            }
          ]}
        />
        <Typography.Text >
          <br />说明：代金券可抵扣的额度不是固定的，随着店铺数和订购周期的增加而增加。具体请看订购页面的可抵扣额度；<br />
        </Typography.Text>


        <Modal title={<>赠送好友<span style={{ fontSize: "12px" }}> - 验证码{couponCode}</span></>} open={showModel_shareCard} footer={null} onCancel={() => this.setStateKeyValue("showModel_shareCard", false)}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <ShareCard
              backgroundImg={backgroundImg}
              qrCodeValue={qrCodeValue}
              writeText={writeText}
              key={writeText}
            />
          </div>
        </Modal>

        <Modal title="" open={showModel_receiveSuccess} footer={null} onCancel={() => this.setStateKeyValue("showModel_receiveSuccess", false)}>
          <Result
            status="success"
            title="代金券领取成功!"
            subTitle={
              receiveCouponData ?
                <Descriptions className="descriptionsStyle" size={"middle"} title={null} column={1}>
                  <Descriptions.Item label="验证码">{receiveCouponData.couponCode}</Descriptions.Item>
                  <Descriptions.Item label="代金券金额">{receiveCouponData.couponMoney}元</Descriptions.Item>
                  <Descriptions.Item label="过期时间">{receiveCouponData.endTimeFormat}</Descriptions.Item>
                </Descriptions>
                : null
            }
            extra={[
              <Button type="primary" key="console" onClick={() => window.location.reload()}>
                查看
              </Button>
              ,
              <Button key="buy" onClick={() => this.setStateKeyValue("showModel_receiveSuccess", false)}>关闭</Button>,
            ]}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default CouponPage;